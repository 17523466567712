/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  query AccountDetail($pk: Int) {\n    accountByPk(pk: $pk) {\n      id\n      pk\n      guid\n      externalId\n      role\n      firstName\n      lastName\n      displayName\n      emailAddress\n      profileImage\n      companyName\n      phone\n      city\n      state\n      notes\n      createdAt\n      firstPurchasedAt\n      hasActiveSubscriptions\n      shopUrl\n      summaryUrl\n      offerContext {\n        hasUnlimited\n        hasWhiteLabeling\n        hasVideoApproval\n        hasVoiceOver\n        hasConcierge\n        latestVideoDownloadSubscriptionValidThrough\n        recurringMonthlyDownloads\n        recurringRolloverDownloads\n        totalRemainingRecurringDownloads\n        totalRemainingDownloads\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            partner {\n              id\n              name\n            }\n          }\n        }\n      }\n      serviceActivities {\n        edges {\n          node {\n            description\n            completedOn\n            adminUser {\n              fullName\n            }\n            activityType {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.AccountDetailDocument,
  '\n  mutation AddCreditsMutation($id: ID!, $creditCount: Int!, $description: String!) {\n    addAccountCredits(input: { id: $id, creditCount: $creditCount, description: $description }) {\n      account {\n        id\n        offerContext {\n          totalRemainingDownloads\n        }\n      }\n    }\n  }\n':
    types.AddCreditsMutationDocument,
  '\n  query AdminAccountList(\n    $first: Int\n    $last: Int\n    $before: String\n    $after: String\n    $orderBy: [String]\n    $id: ID\n    $pk: Int\n    $guid: String\n    $externalId: String\n    $companyName: String\n    $firstName: String\n    $lastName: String\n    $phone: String\n    $emailAddress: String\n    $accountGroupName: String\n    $partnerName: String\n  ) {\n    accounts(\n      first: $first\n      last: $last\n      before: $before\n      after: $after\n      orderBy: $orderBy\n      id: $id\n      pk: $pk\n      guid: $guid\n      externalId: $externalId\n      companyName_Istartswith: $companyName\n      firstName_Istartswith: $firstName\n      lastName_Istartswith: $lastName\n      phone_Startswith: $phone\n      emailAddress_Istartswith: $emailAddress\n      accountGroups_DisplayName_Icontains: $accountGroupName\n      partner_Name_Icontains: $partnerName\n    ) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      totalCount\n\n      edges {\n        cursor\n        node {\n          id\n          pk\n          guid\n          externalId\n          createdAt\n          firstName\n          lastName\n          emailAddress\n          companyName\n          phone\n\n          accountGroups {\n            edges {\n              node {\n                id\n                displayName\n                partner {\n                  id\n                  name\n                }\n              }\n            }\n          }\n\n          partner {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n':
    types.AdminAccountListDocument,
  '\n      query BulkRenderJobDetail($ids: [ID]!) {\n        bulkRenderJobs(ids: $ids) {\n          bulkRenderVideos {\n            id\n            renderId\n            description\n            configuration\n          }\n        }\n      }\n    ':
    types.BulkRenderJobDetailDocument,
  '\n      query BulkRenderingJobs {\n        bulkRenderJobs {\n          id\n          createdAt\n          jobId\n          description\n          configurationMapping\n          destinationAccount {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    ':
    types.BulkRenderingJobsDocument,
  '\n  query ImageLibraryDetail($pk: Int) {\n    imageLibraryByPk(pk: $pk) {\n      ...ImageLibraryFragment\n    }\n  }\n':
    types.ImageLibraryDetailDocument,
  '\n  mutation UpdateImageLibraryImageMutation($input: UpdateImageLibraryImageMutationInput!) {\n    updateImageLibraryImage(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n':
    types.UpdateImageLibraryImageMutationDocument,
  '\n  mutation DeleteImageLibraryImageMutation($id: ID!) {\n    deleteImageLibraryImage(input: { id: $id }) {\n      ok\n    }\n  }\n':
    types.DeleteImageLibraryImageMutationDocument,
  '\n  query ImageLibraryList(\n    $first: Int\n    $last: Int\n    $before: String\n    $after: String\n    $orderBy: [String]\n    $id: ID\n    $pk: Int\n    $slug: String\n    $displayName: String\n  ) {\n    imageLibraries(\n      first: $first\n      last: $last\n      before: $before\n      after: $after\n      orderBy: $orderBy\n      id: $id\n      pk: $pk\n      slug_Icontains: $slug\n      displayName_Icontains: $displayName\n    ) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      totalCount\n\n      edges {\n        cursor\n        node {\n          id\n          pk\n          slug\n          displayName\n          createdAt\n        }\n      }\n    }\n  }\n':
    types.ImageLibraryListDocument,
  '\n  mutation CreateImageLibraryMutation($displayName: String!, $slug: String!) {\n    createImageLibrary(input: { displayName: $displayName, slug: $slug }) {\n      imageLibrary {\n        id\n        pk\n      }\n    }\n  }\n':
    types.CreateImageLibraryMutationDocument,
  '\n      query SelectedPartner($pk: Int) {\n        partnerByPk(pk: $pk) {\n          pk\n          name\n          slug\n        }\n      }\n    ':
    types.SelectedPartnerDocument,
  '\n          query SearchPartnerByName($nameSearchString: String!) {\n            partners(name_Icontains: $nameSearchString) {\n              edges {\n                node {\n                  pk\n                  name\n                  slug\n                }\n              }\n            }\n          }\n        ':
    types.SearchPartnerByNameDocument,
  '\n  query AdminVideoTemplateVariants(\n    $first: Int\n    $offset: Int\n    $orderBy: [String]\n    $searchQuery: String\n  ) {\n    videoTemplateVariants(\n      first: $first\n      offset: $offset\n      orderBy: $orderBy\n      searchQuery: $searchQuery\n    ) {\n      totalCount\n      edges {\n        cursor\n        node {\n          id\n          createdAt\n          slug\n          displayName\n          isEnabled\n          defaultRenderFormat\n          videoTemplate {\n            id\n            slug\n            name\n            permissions\n          }\n        }\n      }\n    }\n  }\n':
    types.AdminVideoTemplateVariantsDocument,
  '\n  query VariantExport($ids: [ID]!) {\n    videoTemplateVariantsByIds(ids: $ids) {\n      creationData\n    }\n  }\n':
    types.VariantExportDocument,
  '\n          query UserVideo($guid: String!) {\n            userVideoByGuid(guid: $guid) {\n              guid\n              title\n              videoConfiguration\n              videoTemplateVariant {\n                slug\n                licensing\n                variantOffer {\n                  offer {\n                    pk\n                  }\n                }\n                videoTemplate {\n                  name\n                  pk\n                  slug\n                  isRetired\n                  permissions\n                  exclusivePartner {\n                    pk\n                    name\n                    slug\n                  }\n                  hasFootage\n                  imageAssetCount\n                }\n                exclusivePartner {\n                  pk\n                  name\n                  slug\n                }\n              }\n            }\n          }\n        ':
    types.UserVideoDocument,
  '\n  query VideoAssetLibraryDetail($pk: Int) {\n    videoLibraryByPk(pk: $pk) {\n      ...CoreVideoLibraryFields\n    }\n  }\n':
    types.VideoAssetLibraryDetailDocument,
  '\n      query AdminVideoAssetLibraryList {\n        videoLibraries {\n          id\n          pk\n          displayName\n          slug\n        }\n      }\n    ':
    types.AdminVideoAssetLibraryListDocument,
  '\n  query VideoAssetLibraryVideoDetail($pk: Int) {\n    videoLibraryVideoByPk(pk: $pk) {\n      ...CoreVideoLibraryVideoFields\n    }\n  }\n':
    types.VideoAssetLibraryVideoDetailDocument,
  '\n  query AccountPartner($id: ID!) {\n    partner(id: $id) {\n      activeUserCount\n      currentMonthPurchasedVideoCount\n      maxActiveUsers\n      maxMonthlyVideos\n    }\n  }\n':
    types.AccountPartnerDocument,
  '\n  query GenerationResultUserVideo($userVideoGUID: String) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      title\n      createdAt\n      purchasedAt\n      business {\n        id\n        guid\n        businessName\n      }\n      videoTemplateVariant {\n        id\n        videoTemplate {\n          id\n          name\n        }\n      }\n      lastEditedByUser\n    }\n  }\n':
    types.GenerationResultUserVideoDocument,
  '\n  query UserVideoOmniSupport($userVideoGUID: String) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      guid\n      videoTemplateVariant {\n        id\n        omniGroupSlug\n      }\n      account {\n        id\n        partner {\n          id\n          hasOmni\n        }\n      }\n    }\n  }\n':
    types.UserVideoOmniSupportDocument,
  '\n  query VideoPreviewPageUserVideo($guid: String!) {\n    userVideoByGuid(guid: $guid) {\n      id\n      guid\n      title\n      purchasedAt\n      hasBeenDeletedByUser\n      lastEditedByUser\n      videoConfiguration\n      voiceOverProduct {\n        id\n        finalVideo\n      }\n      videoTemplateVariant {\n        id\n        guid\n        width\n        height\n        compoundSlug\n      }\n      account {\n        id\n        guid\n      }\n      ...UserVideoRendersFragment\n    }\n  }\n':
    types.VideoPreviewPageUserVideoDocument,
  '\n  query UserVideoShareModalData($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      title\n      purchasedAt\n      feedback {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n':
    types.UserVideoShareModalDataDocument,
  '\n  query AccountPartnerName($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n\n      partner {\n        id\n        name\n      }\n    }\n  }\n':
    types.AccountPartnerNameDocument,
  '\n  fragment CoreAccountFields on AccountRelayNode {\n    id\n    pk\n    createdAt\n    guid\n    displayName\n    firstName\n    lastName\n    companyName\n    emailAddress\n    role\n    lastLoginDate\n    externalId\n    isActive\n  }\n':
    types.CoreAccountFieldsFragmentDoc,
  '\n  fragment AccountAccountGroupFields on AccountRelayNode {\n    accountGroups {\n      edges {\n        node {\n          id\n          guid\n          displayName\n        }\n      }\n    }\n  }\n':
    types.AccountAccountGroupFieldsFragmentDoc,
  '\n  query ManagedAccountDetailsByGUID($accountGUID: String) {\n    accounts(guid: $accountGUID) {\n      edges {\n        node {\n          ...CoreAccountFields\n          ...AccountAccountGroupFields\n        }\n      }\n    }\n  }\n':
    types.ManagedAccountDetailsByGuidDocument,
  '\n  query AccountGroupList($partnerGuid: String) {\n    managedAccountGroups(partnerGuid: $partnerGuid) {\n      id\n      displayName\n      guid\n    }\n  }\n':
    types.AccountGroupListDocument,
  '\n  mutation UpdateAccountsByGUID($input: UpdateAccountMutationInput!) {\n    updateAccount(input: $input) {\n      updatedAccounts {\n        ...CoreAccountFields\n        ...AccountAccountGroupFields\n      }\n    }\n  }\n':
    types.UpdateAccountsByGuidDocument,
  '\n  mutation CreateAccount($input: CreateAccountMutationInput!) {\n    createAccount(input: $input) {\n      createdAccount {\n        ...CoreAccountFields\n        ...AccountAccountGroupFields\n      }\n    }\n  }\n':
    types.CreateAccountDocument,
  '\n  query TakenEmails($emailAddresses: [String]!) {\n    takenEmails(emailAddresses: $emailAddresses)\n  }\n':
    types.TakenEmailsDocument,
  '\n  query PasswordValid($password: String) {\n    passwordValid(password: $password)\n  }\n':
    types.PasswordValidDocument,
  '\n  query CanPurchaseForAccount($accountGuid: String) {\n    canPurchaseForAccount(accountGuid: $accountGuid)\n  }\n':
    types.CanPurchaseForAccountDocument,
  '\n  mutation ApproveUserVideoPurchaseMutation($input: ApproveUserVideoPurchaseMutationInput!) {\n    approveUserVideoPurchase(input: $input) {\n      userVideo {\n        id\n        guid\n        purchasedAt\n      }\n    }\n  }\n':
    types.ApproveUserVideoPurchaseMutationDocument,
  '\n  query CollectionsForUser {\n    videoTemplateCollections {\n      slug\n      displayNameShort\n    }\n  }\n':
    types.CollectionsForUserDocument,
  '\n  query SelectedCollectionDetails($collectionSlug: String) {\n    videoTemplateCollectionBySlug(slug: $collectionSlug) {\n      slug\n      displayNameShort\n      displayName\n      shortDescription\n    }\n  }\n':
    types.SelectedCollectionDetailsDocument,
  '\n  query AllTemplateBrowserFilters {\n    videoTemplateVariants {\n      availableFilters {\n        displayDuration\n      }\n    }\n  }\n':
    types.AllTemplateBrowserFiltersDocument,
  '\n  fragment TemplateBrowserVariantFragment on VideoTemplateVariantRelayNode {\n    id\n    compoundSlug\n    omniGroupSlug\n    displayName\n    displayDuration\n    previewVideoUrl\n    thumbnailImageUrl\n    width\n    height\n    price\n    licensing\n    videoTemplate {\n      permissions\n    }\n  }\n':
    types.TemplateBrowserVariantFragmentFragmentDoc,
  '\n  query TemplateBrowserVariants(\n    $collectionSlug: String\n    $length: [String]\n    $aspectRatio: [String]\n    $hasFootage: Boolean\n    $maxImageAssetCount: Float\n    $minImageAssetCount: Float\n    $orderBy: [String]\n    $first: Int\n    $after: String\n  ) {\n    videoTemplateVariants(\n      groupVariant_VariantGroup_Slug: $collectionSlug\n      displayDuration_In: $length\n      videoTemplate_HasFootage: $hasFootage\n      videoTemplate_ImageAssetCount_Lte: $maxImageAssetCount\n      videoTemplate_ImageAssetCount_Gte: $minImageAssetCount\n      aspectRatio_In: $aspectRatio\n      orderBy: $orderBy\n      first: $first\n      after: $after\n    ) {\n      totalCount\n      edges {\n        cursor\n        node {\n          ...TemplateBrowserVariantFragment\n        }\n      }\n    }\n  }\n':
    types.TemplateBrowserVariantsDocument,
  '\n  query AccountVideoLibrariesDetail($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      videoLibraries {\n        edges {\n          node {\n            ...CoreVideoLibraryFields\n          }\n        }\n      }\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            videoAssetLibraries {\n              edges {\n                node {\n                  ...CoreVideoLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.AccountVideoLibrariesDetailDocument,
  '\n  query BusinessVideoDetailsByGUID($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n\n      videoLibraries {\n        edges {\n          node {\n            ...CoreVideoLibraryFields\n          }\n        }\n      }\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.BusinessVideoDetailsByGuidDocument,
  '\n  query AccountStockVideoLibraryAssetKeys($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      stockVideoLibraries {\n        edges {\n          node {\n            stockVideoLibraryVideos {\n              edges {\n                node {\n                  stockAsset {\n                    id\n                    vpsKey\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.AccountStockVideoLibraryAssetKeysDocument,
  '\n  query BusinessStockVideoLibraryAssetKeys($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      stockVideoLibraries {\n        edges {\n          node {\n            stockVideoLibraryVideos {\n              edges {\n                node {\n                  stockAsset {\n                    id\n                    vpsKey\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.BusinessStockVideoLibraryAssetKeysDocument,
  '\n  mutation CreateAccountVideoLibraryVideo($input: CreateAccountVideoLibraryVideoMutationInput!) {\n    createAccountVideoLibraryVideo(input: $input) {\n      videoLibraryVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n':
    types.CreateAccountVideoLibraryVideoDocument,
  '\n  mutation CreateBusinessVideoLibraryVideo($input: CreateBusinessVideoLibraryVideoMutationInput!) {\n    createBusinessVideoLibraryVideo(input: $input) {\n      videoLibraryVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n':
    types.CreateBusinessVideoLibraryVideoDocument,
  '\n  mutation UpdateVideoLibraryVideo($input: UpdateVideoLibraryVideoMutationInput!) {\n    updateVideoLibraryVideo(input: $input) {\n      updatedVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n':
    types.UpdateVideoLibraryVideoDocument,
  '\n  fragment AccountAudioAssetFields on AccountAudioAssetNode {\n    id\n    guid\n    uploadKey\n    length\n    displayName\n    source\n  }\n':
    types.AccountAudioAssetFieldsFragmentDoc,
  '\n  query AccountAudioAssetForUploadKey($uploadKey: String!) {\n    accountAudioAssetForUploadKey(uploadKey: $uploadKey) {\n      ...AccountAudioAssetFields\n    }\n  }\n':
    types.AccountAudioAssetForUploadKeyDocument,
  '\n  mutation GetOrCreateAccountColorLibrary($input: GetOrCreateAccountColorLibraryMutationInput!) {\n    getOrCreateAccountColorLibrary(input: $input) {\n      colorLibrary {\n        ...ColorLibraryFields\n      }\n      didCreate\n    }\n  }\n':
    types.GetOrCreateAccountColorLibraryDocument,
  '\n  mutation GetOrCreateBusinessColorLibrary($input: GetOrCreateBusinessColorLibraryMutationInput!) {\n    getOrCreateBusinessColorLibrary(input: $input) {\n      colorLibrary {\n        ...ColorLibraryFields\n      }\n      didCreate\n    }\n  }\n':
    types.GetOrCreateBusinessColorLibraryDocument,
  '\n  mutation UpdateOrCreateColorLibraryColor($input: UpdateOrCreateColorLibraryColorMutationInput!) {\n    updateOrCreateColorLibraryColor(input: $input) {\n      color {\n        ...ColorFields\n      }\n      updatedColorLibrary {\n        id\n      }\n    }\n  }\n':
    types.UpdateOrCreateColorLibraryColorDocument,
  '\n  mutation DeleteColorLibraryColor($input: DeleteColorLibraryColorMutationInput!) {\n    deleteColorLibraryColor(input: $input) {\n      removedColorId\n    }\n  }\n':
    types.DeleteColorLibraryColorDocument,
  '\n  fragment ColorFields on ColorLibraryColorNode {\n    id\n    order\n    displayName\n    hexCode\n    updatedAt\n  }\n':
    types.ColorFieldsFragmentDoc,
  '\n  fragment ColorLibraryFields on ColorLibraryNode {\n    id\n    guid\n    displayName\n    colors {\n      edges {\n        node {\n          ...ColorFields\n        }\n      }\n    }\n  }\n':
    types.ColorLibraryFieldsFragmentDoc,
  '\n  query ColorLibrariesForAccount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n      colorLibraries {\n        edges {\n          node {\n            ...ColorLibraryFields\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            colorLibraries {\n              edges {\n                node {\n                  ...ColorLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.ColorLibrariesForAccountDocument,
  '\n  query ColorLibrariesForBusiness($businessGUID: String) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      businessName\n      colorLibraries {\n        edges {\n          node {\n            ...ColorLibraryFields\n          }\n        }\n      }\n    }\n  }\n':
    types.ColorLibrariesForBusinessDocument,
  '\n  mutation GetOrCreateDefaultAccountFontLibrary(\n    $input: GetOrCreateDefaultAccountFontLibraryMutationInput!\n  ) {\n    getOrCreateDefaultAccountFontLibrary(input: $input) {\n      fontLibrary {\n        ...FontLibraryFields\n      }\n    }\n  }\n':
    types.GetOrCreateDefaultAccountFontLibraryDocument,
  '\n  mutation GetOrCreateDefaultBusinessFontLibrary(\n    $input: GetOrCreateDefaultBusinessFontLibraryMutationInput!\n  ) {\n    getOrCreateDefaultBusinessFontLibrary(input: $input) {\n      fontLibrary {\n        ...FontLibraryFields\n      }\n    }\n  }\n':
    types.GetOrCreateDefaultBusinessFontLibraryDocument,
  '\n  mutation AddFontToFontLibrary($input: AddFontToFontLibraryMutationInput!) {\n    addFontToFontLibrary(input: $input) {\n      createdFontLibraryFont {\n        ...FontLibraryFontFields\n      }\n      updatedFontLibrary {\n        id\n      }\n    }\n  }\n':
    types.AddFontToFontLibraryDocument,
  '\n  mutation RemoveFontFromFontLibrary($input: RemoveFontFromFontLibraryMutationInput!) {\n    removeFontFromFontLibrary(input: $input) {\n      removedFontLibraryFontId\n    }\n  }\n':
    types.RemoveFontFromFontLibraryDocument,
  '\n  fragment BFSFontVariantFields on BFSFontVariant {\n    uuid\n    isItalic\n    weightIndex\n  }\n':
    types.BfsFontVariantFieldsFragmentDoc,
  '\n  fragment TemplateFontFields on TemplateFontNode {\n    id\n    bfsUuid\n    fontFamilyName\n    variants {\n      ...BFSFontVariantFields\n    }\n  }\n':
    types.TemplateFontFieldsFragmentDoc,
  '\n  fragment FontLibraryFontFields on FontLibraryFontNode {\n    id\n    guid\n    displayName\n    order\n    updatedAt\n    font {\n      ...TemplateFontFields\n    }\n  }\n':
    types.FontLibraryFontFieldsFragmentDoc,
  '\n  fragment FontLibraryFields on FontLibraryNode {\n    id\n    guid\n    displayName\n    fonts {\n      edges {\n        node {\n          ...FontLibraryFontFields\n        }\n      }\n    }\n  }\n':
    types.FontLibraryFieldsFragmentDoc,
  '\n  query FontLibrariesForBusiness($businessGUID: String) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      businessName\n      guid\n      fontLibraries {\n        edges {\n          node {\n            ...FontLibraryFields\n          }\n        }\n      }\n    }\n  }\n':
    types.FontLibrariesForBusinessDocument,
  '\n  query FontLibrariesForAccount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      displayName\n      guid\n      fontLibraries {\n        edges {\n          node {\n            ...FontLibraryFields\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            fontLibraries {\n              edges {\n                node {\n                  ...FontLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.FontLibrariesForAccountDocument,
  '\n  query GlobalFontLibraries {\n    globalFontLibraries {\n      edges {\n        node {\n          ...FontLibraryFields\n        }\n      }\n    }\n  }\n':
    types.GlobalFontLibrariesDocument,
  '\n  query BusinessImageData($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalImageCount\n      logoImage {\n        id\n        guid\n        baseUrl\n        updatedAt\n      }\n    }\n  }\n':
    types.BusinessImageDataDocument,
  '\n  query AccountImageLibraries($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n      displayName\n\n      imageLibraries {\n        edges {\n          node {\n            ...ImageLibraryFragment\n          }\n        }\n      }\n\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            imageLibraries {\n              edges {\n                node {\n                  ...ImageLibraryFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.AccountImageLibrariesDocument,
  '\n  query BusinessImageLibraries($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalImageCount\n      logoImage {\n        id\n        guid\n        baseUrl\n        updatedAt\n      }\n      businessName\n\n      imageLibraries {\n        edges {\n          node {\n            ...ImageLibraryFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.BusinessImageLibrariesDocument,
  '\n  mutation UpdateUserVideo($input: UpdateUserVideoMutationInput!) {\n    updateUserVideo(input: $input) {\n      updatedUserVideo {\n        id\n        guid\n        # Return updated values for any fields which can be updated in this mutation\n        # so we can update the cache with the new values!\n        title\n        videoConfiguration\n        business {\n          ...CoreBusinessDetailsFragment\n        }\n      }\n    }\n  }\n':
    types.UpdateUserVideoDocument,
  "\n  mutation DeleteUserVideo($input: DeleteUserVideoMutationInput!) {\n    deleteUserVideo(input: $input) {\n      deletedUserVideo {\n        id\n        # Include guid because we use that field for user videos' cache ids\n        guid\n      }\n    }\n  }\n":
    types.DeleteUserVideoDocument,
  '\n  mutation CreateUserVideo($input: CreateUserVideoMutationInput!) {\n    createUserVideo(input: $input) {\n      userVideo {\n        id\n        guid\n      }\n    }\n  }\n':
    types.CreateUserVideoDocument,
  '\n  mutation RenderUserVideo($input: RenderUserVideoMutationInput!) {\n    renderUserVideo(input: $input) {\n      userVideo {\n        ...CoreUserVideoFragment\n        ...UserVideoAccountPageFieldsFragment\n      }\n    }\n  }\n':
    types.RenderUserVideoDocument,
  '\n  mutation CreateUserVideoApprovalRequest($input: CreateUserVideoApprovalRequestMutationInput!) {\n    createUserVideoApprovalRequest(input: $input) {\n      userVideo {\n        id\n        guid\n        userVideoApprovals {\n          ...UserVideoApprovalFragment\n        }\n      }\n    }\n  }\n':
    types.CreateUserVideoApprovalRequestDocument,
  '\n  mutation ApproveUserVideo($input: ApproveUserVideoMutationInput!) {\n    approveUserVideo(input: $input) {\n      userVideo {\n        id\n        guid\n        userVideoApprovals {\n          ...UserVideoApprovalFragment\n        }\n      }\n    }\n  }\n':
    types.ApproveUserVideoDocument,
  '\n  query MostRecentlyEditedUserVideoInfo($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      lastEditedUserVideo: userVideos(first: 1, orderBy: "-last_edited_by_user") {\n        edges {\n          node {\n            id\n            guid\n            lastEditedByUser\n          }\n        }\n      }\n      lastPurchasedUserVideo: userVideos(first: 1, orderBy: "-purchased_at") {\n        edges {\n          node {\n            id\n            guid\n            purchasedAt\n          }\n        }\n      }\n    }\n  }\n':
    types.MostRecentlyEditedUserVideoInfoDocument,
  '\n  fragment UserVideoRenderedVideo on RenderedVideoNode {\n    id\n    renderUrl\n    renderFormat\n    renderStatus\n    renderSize\n    hasWatermark\n  }\n':
    types.UserVideoRenderedVideoFragmentDoc,
  '\n  query UserVideoRenders($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      currentConfiguredVideo {\n        id\n        renderStatus\n        renderedVideos {\n          edges {\n            node {\n              ...UserVideoRenderedVideo\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.UserVideoRendersDocument,
  '\n  query AccountPartners($orderBy: [String]) {\n    partners(orderBy: $orderBy) {\n      edges {\n        node {\n          id\n          guid\n          name\n          slug\n        }\n      }\n    }\n  }\n':
    types.AccountPartnersDocument,
  '\n  query SelectedAccountPartner($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      partner {\n        id\n        guid\n        name\n        slug\n      }\n    }\n  }\n':
    types.SelectedAccountPartnerDocument,
  '\n  query ManagedAccountList(\n    $after: String\n    $first: Int\n    $orderBy: [String]\n    $isActive: Boolean\n    $accountGroupGUID: String\n    $hasNoAccountGroup: Boolean\n    $role: String\n    $searchString: String\n    $partnerGUID: String\n  ) {\n    accounts(\n      after: $after\n      first: $first\n      orderBy: $orderBy\n      isActive: $isActive\n      accountGroups_Guid: $accountGroupGUID\n      accountGroups_Isnull: $hasNoAccountGroup\n      role: $role\n      searchString: $searchString\n      partner_Guid: $partnerGUID\n    ) {\n      pageInfo {\n        endCursor\n      }\n      totalCount\n\n      edges {\n        node {\n          ...CoreAccountFields\n          totalVideosCreated\n          totalVideosCompleted\n          ...AccountAccountGroupFields\n        }\n      }\n    }\n  }\n':
    types.ManagedAccountListDocument,
  '\n  query RefreshBusinessStockVideoLibrary($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalVideoCount\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.RefreshBusinessStockVideoLibraryDocument,
  '\n  query RefreshAccountStockVideoLibrary($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.RefreshAccountStockVideoLibraryDocument,
  '\n  query Partners {\n    partners {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          slug\n          emailDomain\n          canUsersPurchase\n          hasVoiceOver\n          maxActiveUsers\n          maxMonthlyVideos\n          hasUnlimited\n          shouldHideBusinessPersonalization\n          isTvOnlyIntegration\n          accountGroups {\n            edges {\n              node {\n                id\n                guid\n                displayName\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PartnersDocument,
  '\n  query PartnerStatsBySlug($slug: String!, $startDate: DateTime!, $endDate: DateTime!) {\n    partners(slug: $slug) {\n      totalCount\n      edges {\n        node {\n          id\n          visitCount(start: $startDate, end: $endDate)\n          draftVideoCount(start: $startDate, end: $endDate)\n          generatedVideoCount(start: $startDate, end: $endDate)\n          purchasedVideoCount(start: $startDate, end: $endDate)\n          renderedVideoCount(start: $startDate, end: $endDate)\n        }\n      }\n    }\n  }\n':
    types.PartnerStatsBySlugDocument,
  '\n  query PartnerAccountGroupStatsBySlug(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n  ) {\n    partners(slug: $slug) {\n      totalCount\n      edges {\n        node {\n          id\n          accountGroups(guid: $accountGroupGUID) {\n            edges {\n              node {\n                id\n                visitCount(start: $startDate, end: $endDate)\n                draftVideoCount(start: $startDate, end: $endDate)\n                generatedVideoCount(start: $startDate, end: $endDate)\n                purchasedVideoCount(start: $startDate, end: $endDate)\n                renderedVideoCount(start: $startDate, end: $endDate)\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PartnerAccountGroupStatsBySlugDocument,
  '\n  query PartnerUsageByAccount(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n  ) {\n    partnerUsageByAccount(\n      slug: $slug\n      start: $startDate\n      end: $endDate\n      accountGroupGUID: $accountGroupGUID\n    ) {\n      id\n      emailAddress\n      displayName\n      visitCount\n      draftVideoCount\n      purchasedVideoCount\n      generatedVideoCount\n      renderedVideoCount\n    }\n  }\n':
    types.PartnerUsageByAccountDocument,
  '\n  query PartnerUsageByAccountGroup($slug: String!, $startDate: DateTime!, $endDate: DateTime!) {\n    partnerUsageByAccountGroup(slug: $slug, start: $startDate, end: $endDate) {\n      groupName\n      parentName\n      visitCount\n      draftVideoCount\n      purchasedVideoCount\n    }\n  }\n':
    types.PartnerUsageByAccountGroupDocument,
  '\n  query PartnerVideoDetails(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n    $isPurchased: Boolean!\n  ) {\n    partnerVideosByAccount(\n      slug: $slug\n      purchased: $isPurchased\n      start: $startDate\n      end: $endDate\n      accountGroupGUID: $accountGroupGUID\n    ) {\n      accountId\n      accountEmailAddress\n      accountDisplayName\n      videoCreatedAt\n      videoPurchasedAt\n      videoTitle\n      variantName\n      brandName\n      aspectRatio\n      duration\n      licensing\n      videoGuid\n    }\n  }\n':
    types.PartnerVideoDetailsDocument,
  '\n  mutation UpdateAutofillResultVideoDescriptor(\n    $requestGUID: String!\n    $videoDescriptorJSON: String!\n  ) {\n    updateAutofillResult(\n      input: { requestGuid: $requestGUID, videoDescriptorJson: $videoDescriptorJSON }\n    ) {\n      updatedAutofillResult {\n        id\n        videoDescriptor\n      }\n    }\n  }\n':
    types.UpdateAutofillResultVideoDescriptorDocument,
  '\n  mutation CreateAutofillFeedback(\n    $requestGUID: String!\n    $rating: String!\n    $feedbackText: String!\n    $feedbackChipGUIDs: [String!]\n  ) {\n    createAutofillFeedback(\n      input: {\n        autofillRequestGuid: $requestGUID\n        rating: $rating\n        feedbackText: $feedbackText\n        feedbackChipGuids: $feedbackChipGUIDs\n      }\n    ) {\n      createdAutofillFeedback {\n        guid\n        rating\n        feedbackText\n        feedbackChips {\n          edges {\n            node {\n              guid\n              text\n              suggestion\n              suggestionTitle\n              category {\n                guid\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.CreateAutofillFeedbackDocument,
  '\n  mutation UpdateAutofillFeedback(\n    $requestGUID: String!\n    $rating: String!\n    $feedbackText: String!\n    $feedbackChipGUIDs: [String!]\n  ) {\n    updateAutofillFeedback(\n      input: {\n        autofillRequestGuid: $requestGUID\n        rating: $rating\n        feedbackText: $feedbackText\n        feedbackChipGuids: $feedbackChipGUIDs\n      }\n    ) {\n      updatedAutofillFeedback {\n        guid\n        rating\n        feedbackText\n        feedbackChips {\n          edges {\n            node {\n              guid\n              text\n              suggestion\n              suggestionTitle\n              category {\n                guid\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.UpdateAutofillFeedbackDocument,
  '\n  fragment AutofillResultFields on UserVideoAutofillResultNode {\n    id\n    pk\n    createdAt\n    requestGuid\n    requestSource\n    userInstructions\n    vibe\n    videoDescriptor\n    voiceover\n    userVideo {\n      id\n      guid\n      title\n      videoConfiguration\n      purchasedAt\n    }\n    videoTemplateVariant {\n      id\n      compoundSlug\n      displayDuration\n      displayName\n      height\n      width\n    }\n    business {\n      id\n      guid\n      businessName\n    }\n  }\n':
    types.AutofillResultFieldsFragmentDoc,
  '\n  query AutofillResultsForAccount(\n    $accountGUID: String!\n    $maxAgeDays: Float\n    $requestSource: String\n  ) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      autofillResults(maxAgeDays: $maxAgeDays, requestSource: $requestSource) {\n        edges {\n          node {\n            ...AutofillResultFields\n          }\n        }\n      }\n    }\n  }\n':
    types.AutofillResultsForAccountDocument,
  '\n  query AutofillResultByRequestGUID($requestGUID: String!) {\n    autofillResultByRequestGuid(requestGuid: $requestGUID) {\n      ...AutofillResultFields\n    }\n  }\n':
    types.AutofillResultByRequestGuidDocument,
  '\n  query AutofillResultFeedback($requestGUID: String) {\n    autofillResultByRequestGuid(requestGuid: $requestGUID) {\n      autofillFeedback {\n        edges {\n          node {\n            guid\n            rating\n            feedbackText\n            feedbackChips {\n              edges {\n                node {\n                  guid\n                  text\n                  suggestion\n                  suggestionTitle\n                  category {\n                    guid\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.AutofillResultFeedbackDocument,
  '\n  query AutofillFeedbackChips {\n    autofillFeedbackChipCategories {\n      guid\n      name\n      feedbackChips {\n        edges {\n          node {\n            guid\n            text\n            suggestion\n            suggestionTitle\n          }\n        }\n      }\n    }\n  }\n':
    types.AutofillFeedbackChipsDocument,
  '\n  fragment BusinessSearchBusinessDataUpdate on BusinessRelayNode {\n    id\n    guid\n    pk\n    businessName\n    contactPhone\n    streetAddress1\n    city\n    state\n    postalCode\n    businessAbout\n    industryName\n    websiteUrl\n  }\n':
    types.BusinessSearchBusinessDataUpdateFragmentDoc,
  '\n  fragment BusinessSearchColorsCreated on ColorLibraryColorNode {\n    ...ColorLibraryColorFragment\n    colorLibrary {\n      id\n      guid\n    }\n  }\n':
    types.BusinessSearchColorsCreatedFragmentDoc,
  '\n  fragment BusinessSearchImagesCreated on ImageLibraryImageNode {\n    ...ImageLibraryImageFragment\n    imageLibrary {\n      id\n      pk\n    }\n  }\n':
    types.BusinessSearchImagesCreatedFragmentDoc,
  '\n  fragment CoreBusinessDetailsFragment on BusinessRelayNode {\n    id\n    pk\n    createdAt\n    guid\n    isVisible\n    businessName\n    businessAbout\n    websiteUrl\n    streetAddress1\n    streetAddress2\n    city\n    state\n    postalCode\n    contactPhone\n    industryName\n    totalImageCount\n    totalVideoCount\n    logoImage {\n      ...ImageLibraryImageFragment\n    }\n    colorLibraries {\n      edges {\n        node {\n          ...ColorLibraryFragment\n        }\n      }\n    }\n  }\n':
    types.CoreBusinessDetailsFragmentFragmentDoc,
  '\n  mutation CreateBusiness($input: CreateBusinessMutationInput!) {\n    createBusiness(input: $input) {\n      createdBusiness {\n        ...CoreBusinessDetailsFragment\n      }\n    }\n  }\n':
    types.CreateBusinessDocument,
  '\n  mutation UpdateBusiness($input: UpdateBusinessMutationInput!) {\n    updateBusiness(input: $input) {\n      updatedBusiness {\n        ...CoreBusinessDetailsFragment\n      }\n    }\n  }\n':
    types.UpdateBusinessDocument,
  '\n  mutation DeleteBusiness($input: DeleteBusinessMutationInput!) {\n    deleteBusiness(input: $input) {\n      deletedBusinessId\n    }\n  }\n':
    types.DeleteBusinessDocument,
  '\n  query BusinessDetailsByGUID($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      ...CoreBusinessDetailsFragment\n    }\n  }\n':
    types.BusinessDetailsByGuidDocument,
  '\n  query ListAllBusinessProfilesForAccount($accountGUID: String!, $orderBy: [String]) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      businesses(orderBy: $orderBy) {\n        edges {\n          node {\n            ...CoreBusinessDetailsFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.ListAllBusinessProfilesForAccountDocument,
  '\n  query BusinessVideoCount($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalVideoCount\n    }\n  }\n':
    types.BusinessVideoCountDocument,
  '\n  fragment ColorLibraryColorFragment on ColorLibraryColorNode {\n    id\n    order\n    displayName\n    hexCode\n    updatedAt\n  }\n':
    types.ColorLibraryColorFragmentFragmentDoc,
  '\n  fragment ColorLibraryFragment on ColorLibraryNode {\n    id\n    guid\n    displayName\n    colors {\n      edges {\n        node {\n          ...ColorLibraryColorFragment\n        }\n      }\n    }\n  }\n':
    types.ColorLibraryFragmentFragmentDoc,
  '\n  fragment ImageLibraryImageFragment on ImageLibraryImageNode {\n    __typename\n    id\n    pk\n    guid\n    displayName\n    image\n    order\n    source\n    imageType\n    isFavorite\n    removedAt\n    updatedAt\n    lastUsedAt\n    baseUrl\n    imageWidth\n    imageHeight\n    upscaledImageUrl\n    stockAssetId\n    stockSearchId\n  }\n':
    types.ImageLibraryImageFragmentFragmentDoc,
  '\n  fragment ImageLibraryFragment on ImageLibraryNode {\n    id\n    pk\n    slug\n    displayName\n    images {\n      edges {\n        node {\n          ...ImageLibraryImageFragment\n        }\n      }\n    }\n  }\n':
    types.ImageLibraryFragmentFragmentDoc,
  '\n  mutation GetOrCreateDefaultAccountImageLibrary(\n    $input: GetOrCreateDefaultAccountImageLibraryMutationInput!\n  ) {\n    getOrCreateDefaultAccountImageLibrary(input: $input) {\n      imageLibrary {\n        ...ImageLibraryFragment\n      }\n    }\n  }\n':
    types.GetOrCreateDefaultAccountImageLibraryDocument,
  '\n  mutation GetOrCreateDefaultBusinessImageLibrary(\n    $input: GetOrCreateDefaultBusinessImageLibraryMutationInput!\n  ) {\n    getOrCreateDefaultBusinessImageLibrary(input: $input) {\n      imageLibrary {\n        ...ImageLibraryFragment\n      }\n    }\n  }\n':
    types.GetOrCreateDefaultBusinessImageLibraryDocument,
  '\n  mutation AddImageToImageLibraryMutation($input: AddImageToImageLibraryMutationInput!) {\n    addImageToImageLibrary(input: $input) {\n      createdImageLibraryImage {\n        ...ImageLibraryImageFragment\n      }\n      updatedImageLibrary {\n        id\n        images {\n          edges {\n            node {\n              ...ImageLibraryImageFragment\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.AddImageToImageLibraryMutationDocument,
  '\n  mutation UpdateImageLibraryImage($input: UpdateImageLibraryImageMutationInput!) {\n    updateImageLibraryImage(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n':
    types.UpdateImageLibraryImageDocument,
  '\n  mutation UpdateImageLibraryImageLastUsedAt(\n    $input: UpdateImageLibraryImageLastUsedAtMutationInput!\n  ) {\n    updateImageLibraryImageLastUsedAt(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n':
    types.UpdateImageLibraryImageLastUsedAtDocument,
  '\n  query StockImagesForImageKeys($imageKeys: [String!]!) {\n    stockImagesForImageKeys(imageKeys: $imageKeys) {\n      id\n      stockAssetId\n      stockSearchId\n    }\n  }\n':
    types.StockImagesForImageKeysDocument,
  '\n  fragment StockVideoAssetFragment on StockVideoAssetNode {\n    __typename\n    id\n    source\n    sourceAssetId\n    width\n    height\n    length\n    vpsKey\n  }\n':
    types.StockVideoAssetFragmentFragmentDoc,
  '\n  fragment StockVideoLibraryVideoFragment on StockVideoLibraryVideoNode {\n    __typename\n    id\n    guid\n    removedAt\n    updatedAt\n    stockAsset {\n      ...StockVideoAssetFragment\n    }\n  }\n':
    types.StockVideoLibraryVideoFragmentFragmentDoc,
  '\n  fragment StockVideoLibraryFragment on StockVideoLibraryNode {\n    __typename\n    id\n    guid\n    displayName\n    stockVideoLibraryVideos {\n      __typename\n      edges {\n        __typename\n        node {\n          ...StockVideoLibraryVideoFragment\n        }\n      }\n    }\n  }\n':
    types.StockVideoLibraryFragmentFragmentDoc,
  '\n  mutation AddStockVideoToLibrary($input: AddStockVideoToLibraryMutationInput!) {\n    addStockVideoToLibrary(input: $input) {\n      createdStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n      createdStockVideoLibrary {\n        id\n        guid\n        displayName\n      }\n    }\n  }\n':
    types.AddStockVideoToLibraryDocument,
  '\n  mutation RemoveStockVideoFromLibrary($input: RemoveStockVideoFromLibraryMutationInput!) {\n    removeStockVideoFromLibrary(input: $input) {\n      updatedStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n    }\n  }\n':
    types.RemoveStockVideoFromLibraryDocument,
  '\n  mutation RestoreRemovedStockVideo($input: RestoreRemovedStockVideoToLibraryMutationInput!) {\n    restoreRemovedStockVideoToLibrary(input: $input) {\n      updatedStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n    }\n  }\n':
    types.RestoreRemovedStockVideoDocument,
  '\n  fragment CoreUserVideoFragment on UserVideoNode {\n    id\n    guid\n    title\n    createdAt\n    updatedAt\n    purchasedAt\n    lastEditedByUser\n    lastEditableDate\n    business {\n      id\n      guid\n      businessName\n    }\n    videoTemplateVariant {\n      id\n      guid\n      displayName\n      displayDuration\n      width\n      height\n      videoTemplate {\n        id\n        isWaymarkAuthorTemplate\n      }\n    }\n  }\n':
    types.CoreUserVideoFragmentFragmentDoc,
  '\n  fragment UserVideoApprovalFragment on UserVideoApprovalNode {\n    id\n    guid\n    createdAt\n    fullName\n    approvedAt\n  }\n':
    types.UserVideoApprovalFragmentFragmentDoc,
  '\n  fragment UserVideoAccountPageFieldsFragment on UserVideoNode {\n    ...CoreUserVideoFragment\n    currentConfiguredVideo {\n      id\n      thumbnailUrl\n    }\n    purchaseApproval {\n      id\n      guid\n      createdAt\n      approvedAt\n    }\n    userVideoApprovals {\n      ...UserVideoApprovalFragment\n    }\n    voiceOverProduct {\n      id\n      isDelivered\n    }\n    derivedUserVideos {\n      totalCount\n    }\n    originalUserVideo {\n      id\n      guid\n      title\n    }\n  }\n':
    types.UserVideoAccountPageFieldsFragmentFragmentDoc,
  '\n  fragment UserVideoRendersFragment on UserVideoNode {\n    id\n    currentConfiguredVideo {\n      id\n      guid\n      renderStatus\n      thumbnailUrl\n      renderedVideos {\n        edges {\n          node {\n            id\n            guid\n            renderUrl\n            renderFormat\n            renderedAt\n            renderStatus\n            renderSize\n            hasWatermark\n            createdAt\n          }\n        }\n      }\n    }\n  }\n':
    types.UserVideoRendersFragmentFragmentDoc,
  '\n  fragment UserVideoFeedbackFragment on UserVideoFeedbackNode {\n    id\n    guid\n    rating\n    feedbackText\n    timeToCreate\n  }\n':
    types.UserVideoFeedbackFragmentFragmentDoc,
  '\n  mutation CreateUserVideoFeedback($input: CreateUserVideoFeedbackMutationInput!) {\n    createUserVideoFeedback(input: $input) {\n      updatedUserVideo {\n        id\n        guid\n        feedback {\n          edges {\n            node {\n              ...UserVideoFeedbackFragment\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.CreateUserVideoFeedbackDocument,
  '\n  query AccountUserVideoCount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      userVideos {\n        totalCount\n      }\n    }\n  }\n':
    types.AccountUserVideoCountDocument,
  '\n  query AccountPageUserVideos(\n    $accountGUID: String!\n    $first: Int\n    $after: String\n    $orderBy: [String]\n    $isPurchased: Boolean\n    $searchQuery: String\n  ) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      unfilteredUserVideos: userVideos {\n        totalCount\n      }\n      userVideos(\n        first: $first\n        after: $after\n        orderBy: $orderBy\n        isPurchased: $isPurchased\n        searchQuery: $searchQuery\n      ) {\n        totalCount\n        edges {\n          cursor\n          node {\n            ...UserVideoAccountPageFieldsFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.AccountPageUserVideosDocument,
  '\n  query AccountPageUserVideoAndDerivedVideos(\n    $userVideoGUID: String\n    $orderBy: [String]\n    $searchQuery: String\n  ) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      ...UserVideoAccountPageFieldsFragment\n      derivedAccountPageUserVideos: derivedUserVideos(\n        orderBy: $orderBy\n        searchQuery: $searchQuery\n      ) {\n        totalCount\n        edges {\n          node {\n            ...UserVideoAccountPageFieldsFragment\n          }\n        }\n      }\n    }\n  }\n':
    types.AccountPageUserVideoAndDerivedVideosDocument,
  '\n  fragment SDKUserVideo on UserVideoNode {\n    ...CoreUserVideoFragment\n    ...UserVideoRendersFragment\n  }\n':
    types.SdkUserVideoFragmentDoc,
  '\n  query SDKAllAccountUserVideos($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      userVideos {\n        edges {\n          node {\n            ...SDKUserVideo\n          }\n        }\n      }\n    }\n  }\n':
    types.SdkAllAccountUserVideosDocument,
  '\n  query UserVideoByGUID($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      ...SDKUserVideo\n    }\n  }\n':
    types.UserVideoByGuidDocument,
  '\n  query UserVideoApprovals($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      userVideoApprovals {\n        ...UserVideoApprovalFragment\n      }\n    }\n  }\n':
    types.UserVideoApprovalsDocument,
  '\n  query VariantForAI(\n    $aspectRatio: String\n    $displayDuration: Int\n    $vibe: String\n    $hasFootage: Boolean\n    $lastSelectedVariantGuid: String\n  ) {\n    videoTemplateVariantForAi(\n      aspectRatio: $aspectRatio\n      displayDuration: $displayDuration\n      vibe: $vibe\n      hasFootage: $hasFootage\n      lastSelectedVariantGuid: $lastSelectedVariantGuid\n    ) {\n      id\n      guid\n      createdAt\n      slug\n      omniGroupSlug\n      displayName\n      displayDuration\n      previewVideoUrl\n      thumbnailImageUrl\n      isPartnerExclusive\n      width\n      height\n      price\n      vibe\n      compoundSlug\n      aspectRatio\n    }\n  }\n':
    types.VariantForAiDocument,
  '\n  query AudioForAI($duration: Int, $vibe: String) {\n    videoTemplateAudioForAi(duration: $duration, vibe: $vibe) {\n      id\n      name\n      transcodedAudio\n      duration\n    }\n  }\n':
    types.AudioForAiDocument,
  '\n  fragment CoreVideoLibraryVideoFields on VideoAssetLibraryVideoNode {\n    id\n    pk\n    guid\n    uploadKey\n    displayName\n    width\n    height\n    length\n    order\n    updatedAt\n    removedAt\n  }\n':
    types.CoreVideoLibraryVideoFieldsFragmentDoc,
  '\n  fragment CoreVideoLibraryFields on VideoAssetLibraryNode {\n    id\n    pk\n    slug\n    displayName\n\n    videoAssets {\n      edges {\n        node {\n          ...CoreVideoLibraryVideoFields\n        }\n      }\n    }\n  }\n':
    types.CoreVideoLibraryFieldsFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountDetail($pk: Int) {\n    accountByPk(pk: $pk) {\n      id\n      pk\n      guid\n      externalId\n      role\n      firstName\n      lastName\n      displayName\n      emailAddress\n      profileImage\n      companyName\n      phone\n      city\n      state\n      notes\n      createdAt\n      firstPurchasedAt\n      hasActiveSubscriptions\n      shopUrl\n      summaryUrl\n      offerContext {\n        hasUnlimited\n        hasWhiteLabeling\n        hasVideoApproval\n        hasVoiceOver\n        hasConcierge\n        latestVideoDownloadSubscriptionValidThrough\n        recurringMonthlyDownloads\n        recurringRolloverDownloads\n        totalRemainingRecurringDownloads\n        totalRemainingDownloads\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            partner {\n              id\n              name\n            }\n          }\n        }\n      }\n      serviceActivities {\n        edges {\n          node {\n            description\n            completedOn\n            adminUser {\n              fullName\n            }\n            activityType {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountDetail($pk: Int) {\n    accountByPk(pk: $pk) {\n      id\n      pk\n      guid\n      externalId\n      role\n      firstName\n      lastName\n      displayName\n      emailAddress\n      profileImage\n      companyName\n      phone\n      city\n      state\n      notes\n      createdAt\n      firstPurchasedAt\n      hasActiveSubscriptions\n      shopUrl\n      summaryUrl\n      offerContext {\n        hasUnlimited\n        hasWhiteLabeling\n        hasVideoApproval\n        hasVoiceOver\n        hasConcierge\n        latestVideoDownloadSubscriptionValidThrough\n        recurringMonthlyDownloads\n        recurringRolloverDownloads\n        totalRemainingRecurringDownloads\n        totalRemainingDownloads\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            partner {\n              id\n              name\n            }\n          }\n        }\n      }\n      serviceActivities {\n        edges {\n          node {\n            description\n            completedOn\n            adminUser {\n              fullName\n            }\n            activityType {\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddCreditsMutation($id: ID!, $creditCount: Int!, $description: String!) {\n    addAccountCredits(input: { id: $id, creditCount: $creditCount, description: $description }) {\n      account {\n        id\n        offerContext {\n          totalRemainingDownloads\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddCreditsMutation($id: ID!, $creditCount: Int!, $description: String!) {\n    addAccountCredits(input: { id: $id, creditCount: $creditCount, description: $description }) {\n      account {\n        id\n        offerContext {\n          totalRemainingDownloads\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AdminAccountList(\n    $first: Int\n    $last: Int\n    $before: String\n    $after: String\n    $orderBy: [String]\n    $id: ID\n    $pk: Int\n    $guid: String\n    $externalId: String\n    $companyName: String\n    $firstName: String\n    $lastName: String\n    $phone: String\n    $emailAddress: String\n    $accountGroupName: String\n    $partnerName: String\n  ) {\n    accounts(\n      first: $first\n      last: $last\n      before: $before\n      after: $after\n      orderBy: $orderBy\n      id: $id\n      pk: $pk\n      guid: $guid\n      externalId: $externalId\n      companyName_Istartswith: $companyName\n      firstName_Istartswith: $firstName\n      lastName_Istartswith: $lastName\n      phone_Startswith: $phone\n      emailAddress_Istartswith: $emailAddress\n      accountGroups_DisplayName_Icontains: $accountGroupName\n      partner_Name_Icontains: $partnerName\n    ) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      totalCount\n\n      edges {\n        cursor\n        node {\n          id\n          pk\n          guid\n          externalId\n          createdAt\n          firstName\n          lastName\n          emailAddress\n          companyName\n          phone\n\n          accountGroups {\n            edges {\n              node {\n                id\n                displayName\n                partner {\n                  id\n                  name\n                }\n              }\n            }\n          }\n\n          partner {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AdminAccountList(\n    $first: Int\n    $last: Int\n    $before: String\n    $after: String\n    $orderBy: [String]\n    $id: ID\n    $pk: Int\n    $guid: String\n    $externalId: String\n    $companyName: String\n    $firstName: String\n    $lastName: String\n    $phone: String\n    $emailAddress: String\n    $accountGroupName: String\n    $partnerName: String\n  ) {\n    accounts(\n      first: $first\n      last: $last\n      before: $before\n      after: $after\n      orderBy: $orderBy\n      id: $id\n      pk: $pk\n      guid: $guid\n      externalId: $externalId\n      companyName_Istartswith: $companyName\n      firstName_Istartswith: $firstName\n      lastName_Istartswith: $lastName\n      phone_Startswith: $phone\n      emailAddress_Istartswith: $emailAddress\n      accountGroups_DisplayName_Icontains: $accountGroupName\n      partner_Name_Icontains: $partnerName\n    ) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      totalCount\n\n      edges {\n        cursor\n        node {\n          id\n          pk\n          guid\n          externalId\n          createdAt\n          firstName\n          lastName\n          emailAddress\n          companyName\n          phone\n\n          accountGroups {\n            edges {\n              node {\n                id\n                displayName\n                partner {\n                  id\n                  name\n                }\n              }\n            }\n          }\n\n          partner {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n      query BulkRenderJobDetail($ids: [ID]!) {\n        bulkRenderJobs(ids: $ids) {\n          bulkRenderVideos {\n            id\n            renderId\n            description\n            configuration\n          }\n        }\n      }\n    ',
): (typeof documents)['\n      query BulkRenderJobDetail($ids: [ID]!) {\n        bulkRenderJobs(ids: $ids) {\n          bulkRenderVideos {\n            id\n            renderId\n            description\n            configuration\n          }\n        }\n      }\n    '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n      query BulkRenderingJobs {\n        bulkRenderJobs {\n          id\n          createdAt\n          jobId\n          description\n          configurationMapping\n          destinationAccount {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    ',
): (typeof documents)['\n      query BulkRenderingJobs {\n        bulkRenderJobs {\n          id\n          createdAt\n          jobId\n          description\n          configurationMapping\n          destinationAccount {\n            id\n            firstName\n            lastName\n          }\n        }\n      }\n    '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ImageLibraryDetail($pk: Int) {\n    imageLibraryByPk(pk: $pk) {\n      ...ImageLibraryFragment\n    }\n  }\n',
): (typeof documents)['\n  query ImageLibraryDetail($pk: Int) {\n    imageLibraryByPk(pk: $pk) {\n      ...ImageLibraryFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateImageLibraryImageMutation($input: UpdateImageLibraryImageMutationInput!) {\n    updateImageLibraryImage(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateImageLibraryImageMutation($input: UpdateImageLibraryImageMutationInput!) {\n    updateImageLibraryImage(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteImageLibraryImageMutation($id: ID!) {\n    deleteImageLibraryImage(input: { id: $id }) {\n      ok\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteImageLibraryImageMutation($id: ID!) {\n    deleteImageLibraryImage(input: { id: $id }) {\n      ok\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ImageLibraryList(\n    $first: Int\n    $last: Int\n    $before: String\n    $after: String\n    $orderBy: [String]\n    $id: ID\n    $pk: Int\n    $slug: String\n    $displayName: String\n  ) {\n    imageLibraries(\n      first: $first\n      last: $last\n      before: $before\n      after: $after\n      orderBy: $orderBy\n      id: $id\n      pk: $pk\n      slug_Icontains: $slug\n      displayName_Icontains: $displayName\n    ) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      totalCount\n\n      edges {\n        cursor\n        node {\n          id\n          pk\n          slug\n          displayName\n          createdAt\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ImageLibraryList(\n    $first: Int\n    $last: Int\n    $before: String\n    $after: String\n    $orderBy: [String]\n    $id: ID\n    $pk: Int\n    $slug: String\n    $displayName: String\n  ) {\n    imageLibraries(\n      first: $first\n      last: $last\n      before: $before\n      after: $after\n      orderBy: $orderBy\n      id: $id\n      pk: $pk\n      slug_Icontains: $slug\n      displayName_Icontains: $displayName\n    ) {\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n      }\n      totalCount\n\n      edges {\n        cursor\n        node {\n          id\n          pk\n          slug\n          displayName\n          createdAt\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateImageLibraryMutation($displayName: String!, $slug: String!) {\n    createImageLibrary(input: { displayName: $displayName, slug: $slug }) {\n      imageLibrary {\n        id\n        pk\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateImageLibraryMutation($displayName: String!, $slug: String!) {\n    createImageLibrary(input: { displayName: $displayName, slug: $slug }) {\n      imageLibrary {\n        id\n        pk\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n      query SelectedPartner($pk: Int) {\n        partnerByPk(pk: $pk) {\n          pk\n          name\n          slug\n        }\n      }\n    ',
): (typeof documents)['\n      query SelectedPartner($pk: Int) {\n        partnerByPk(pk: $pk) {\n          pk\n          name\n          slug\n        }\n      }\n    '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query SearchPartnerByName($nameSearchString: String!) {\n            partners(name_Icontains: $nameSearchString) {\n              edges {\n                node {\n                  pk\n                  name\n                  slug\n                }\n              }\n            }\n          }\n        ',
): (typeof documents)['\n          query SearchPartnerByName($nameSearchString: String!) {\n            partners(name_Icontains: $nameSearchString) {\n              edges {\n                node {\n                  pk\n                  name\n                  slug\n                }\n              }\n            }\n          }\n        '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AdminVideoTemplateVariants(\n    $first: Int\n    $offset: Int\n    $orderBy: [String]\n    $searchQuery: String\n  ) {\n    videoTemplateVariants(\n      first: $first\n      offset: $offset\n      orderBy: $orderBy\n      searchQuery: $searchQuery\n    ) {\n      totalCount\n      edges {\n        cursor\n        node {\n          id\n          createdAt\n          slug\n          displayName\n          isEnabled\n          defaultRenderFormat\n          videoTemplate {\n            id\n            slug\n            name\n            permissions\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AdminVideoTemplateVariants(\n    $first: Int\n    $offset: Int\n    $orderBy: [String]\n    $searchQuery: String\n  ) {\n    videoTemplateVariants(\n      first: $first\n      offset: $offset\n      orderBy: $orderBy\n      searchQuery: $searchQuery\n    ) {\n      totalCount\n      edges {\n        cursor\n        node {\n          id\n          createdAt\n          slug\n          displayName\n          isEnabled\n          defaultRenderFormat\n          videoTemplate {\n            id\n            slug\n            name\n            permissions\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VariantExport($ids: [ID]!) {\n    videoTemplateVariantsByIds(ids: $ids) {\n      creationData\n    }\n  }\n',
): (typeof documents)['\n  query VariantExport($ids: [ID]!) {\n    videoTemplateVariantsByIds(ids: $ids) {\n      creationData\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n          query UserVideo($guid: String!) {\n            userVideoByGuid(guid: $guid) {\n              guid\n              title\n              videoConfiguration\n              videoTemplateVariant {\n                slug\n                licensing\n                variantOffer {\n                  offer {\n                    pk\n                  }\n                }\n                videoTemplate {\n                  name\n                  pk\n                  slug\n                  isRetired\n                  permissions\n                  exclusivePartner {\n                    pk\n                    name\n                    slug\n                  }\n                  hasFootage\n                  imageAssetCount\n                }\n                exclusivePartner {\n                  pk\n                  name\n                  slug\n                }\n              }\n            }\n          }\n        ',
): (typeof documents)['\n          query UserVideo($guid: String!) {\n            userVideoByGuid(guid: $guid) {\n              guid\n              title\n              videoConfiguration\n              videoTemplateVariant {\n                slug\n                licensing\n                variantOffer {\n                  offer {\n                    pk\n                  }\n                }\n                videoTemplate {\n                  name\n                  pk\n                  slug\n                  isRetired\n                  permissions\n                  exclusivePartner {\n                    pk\n                    name\n                    slug\n                  }\n                  hasFootage\n                  imageAssetCount\n                }\n                exclusivePartner {\n                  pk\n                  name\n                  slug\n                }\n              }\n            }\n          }\n        '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VideoAssetLibraryDetail($pk: Int) {\n    videoLibraryByPk(pk: $pk) {\n      ...CoreVideoLibraryFields\n    }\n  }\n',
): (typeof documents)['\n  query VideoAssetLibraryDetail($pk: Int) {\n    videoLibraryByPk(pk: $pk) {\n      ...CoreVideoLibraryFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n      query AdminVideoAssetLibraryList {\n        videoLibraries {\n          id\n          pk\n          displayName\n          slug\n        }\n      }\n    ',
): (typeof documents)['\n      query AdminVideoAssetLibraryList {\n        videoLibraries {\n          id\n          pk\n          displayName\n          slug\n        }\n      }\n    '];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VideoAssetLibraryVideoDetail($pk: Int) {\n    videoLibraryVideoByPk(pk: $pk) {\n      ...CoreVideoLibraryVideoFields\n    }\n  }\n',
): (typeof documents)['\n  query VideoAssetLibraryVideoDetail($pk: Int) {\n    videoLibraryVideoByPk(pk: $pk) {\n      ...CoreVideoLibraryVideoFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountPartner($id: ID!) {\n    partner(id: $id) {\n      activeUserCount\n      currentMonthPurchasedVideoCount\n      maxActiveUsers\n      maxMonthlyVideos\n    }\n  }\n',
): (typeof documents)['\n  query AccountPartner($id: ID!) {\n    partner(id: $id) {\n      activeUserCount\n      currentMonthPurchasedVideoCount\n      maxActiveUsers\n      maxMonthlyVideos\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GenerationResultUserVideo($userVideoGUID: String) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      title\n      createdAt\n      purchasedAt\n      business {\n        id\n        guid\n        businessName\n      }\n      videoTemplateVariant {\n        id\n        videoTemplate {\n          id\n          name\n        }\n      }\n      lastEditedByUser\n    }\n  }\n',
): (typeof documents)['\n  query GenerationResultUserVideo($userVideoGUID: String) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      title\n      createdAt\n      purchasedAt\n      business {\n        id\n        guid\n        businessName\n      }\n      videoTemplateVariant {\n        id\n        videoTemplate {\n          id\n          name\n        }\n      }\n      lastEditedByUser\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserVideoOmniSupport($userVideoGUID: String) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      guid\n      videoTemplateVariant {\n        id\n        omniGroupSlug\n      }\n      account {\n        id\n        partner {\n          id\n          hasOmni\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UserVideoOmniSupport($userVideoGUID: String) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      guid\n      videoTemplateVariant {\n        id\n        omniGroupSlug\n      }\n      account {\n        id\n        partner {\n          id\n          hasOmni\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VideoPreviewPageUserVideo($guid: String!) {\n    userVideoByGuid(guid: $guid) {\n      id\n      guid\n      title\n      purchasedAt\n      hasBeenDeletedByUser\n      lastEditedByUser\n      videoConfiguration\n      voiceOverProduct {\n        id\n        finalVideo\n      }\n      videoTemplateVariant {\n        id\n        guid\n        width\n        height\n        compoundSlug\n      }\n      account {\n        id\n        guid\n      }\n      ...UserVideoRendersFragment\n    }\n  }\n',
): (typeof documents)['\n  query VideoPreviewPageUserVideo($guid: String!) {\n    userVideoByGuid(guid: $guid) {\n      id\n      guid\n      title\n      purchasedAt\n      hasBeenDeletedByUser\n      lastEditedByUser\n      videoConfiguration\n      voiceOverProduct {\n        id\n        finalVideo\n      }\n      videoTemplateVariant {\n        id\n        guid\n        width\n        height\n        compoundSlug\n      }\n      account {\n        id\n        guid\n      }\n      ...UserVideoRendersFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserVideoShareModalData($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      title\n      purchasedAt\n      feedback {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UserVideoShareModalData($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      title\n      purchasedAt\n      feedback {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountPartnerName($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n\n      partner {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountPartnerName($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n\n      partner {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CoreAccountFields on AccountRelayNode {\n    id\n    pk\n    createdAt\n    guid\n    displayName\n    firstName\n    lastName\n    companyName\n    emailAddress\n    role\n    lastLoginDate\n    externalId\n    isActive\n  }\n',
): (typeof documents)['\n  fragment CoreAccountFields on AccountRelayNode {\n    id\n    pk\n    createdAt\n    guid\n    displayName\n    firstName\n    lastName\n    companyName\n    emailAddress\n    role\n    lastLoginDate\n    externalId\n    isActive\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AccountAccountGroupFields on AccountRelayNode {\n    accountGroups {\n      edges {\n        node {\n          id\n          guid\n          displayName\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment AccountAccountGroupFields on AccountRelayNode {\n    accountGroups {\n      edges {\n        node {\n          id\n          guid\n          displayName\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ManagedAccountDetailsByGUID($accountGUID: String) {\n    accounts(guid: $accountGUID) {\n      edges {\n        node {\n          ...CoreAccountFields\n          ...AccountAccountGroupFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ManagedAccountDetailsByGUID($accountGUID: String) {\n    accounts(guid: $accountGUID) {\n      edges {\n        node {\n          ...CoreAccountFields\n          ...AccountAccountGroupFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountGroupList($partnerGuid: String) {\n    managedAccountGroups(partnerGuid: $partnerGuid) {\n      id\n      displayName\n      guid\n    }\n  }\n',
): (typeof documents)['\n  query AccountGroupList($partnerGuid: String) {\n    managedAccountGroups(partnerGuid: $partnerGuid) {\n      id\n      displayName\n      guid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateAccountsByGUID($input: UpdateAccountMutationInput!) {\n    updateAccount(input: $input) {\n      updatedAccounts {\n        ...CoreAccountFields\n        ...AccountAccountGroupFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateAccountsByGUID($input: UpdateAccountMutationInput!) {\n    updateAccount(input: $input) {\n      updatedAccounts {\n        ...CoreAccountFields\n        ...AccountAccountGroupFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateAccount($input: CreateAccountMutationInput!) {\n    createAccount(input: $input) {\n      createdAccount {\n        ...CoreAccountFields\n        ...AccountAccountGroupFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAccount($input: CreateAccountMutationInput!) {\n    createAccount(input: $input) {\n      createdAccount {\n        ...CoreAccountFields\n        ...AccountAccountGroupFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TakenEmails($emailAddresses: [String]!) {\n    takenEmails(emailAddresses: $emailAddresses)\n  }\n',
): (typeof documents)['\n  query TakenEmails($emailAddresses: [String]!) {\n    takenEmails(emailAddresses: $emailAddresses)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PasswordValid($password: String) {\n    passwordValid(password: $password)\n  }\n',
): (typeof documents)['\n  query PasswordValid($password: String) {\n    passwordValid(password: $password)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CanPurchaseForAccount($accountGuid: String) {\n    canPurchaseForAccount(accountGuid: $accountGuid)\n  }\n',
): (typeof documents)['\n  query CanPurchaseForAccount($accountGuid: String) {\n    canPurchaseForAccount(accountGuid: $accountGuid)\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ApproveUserVideoPurchaseMutation($input: ApproveUserVideoPurchaseMutationInput!) {\n    approveUserVideoPurchase(input: $input) {\n      userVideo {\n        id\n        guid\n        purchasedAt\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation ApproveUserVideoPurchaseMutation($input: ApproveUserVideoPurchaseMutationInput!) {\n    approveUserVideoPurchase(input: $input) {\n      userVideo {\n        id\n        guid\n        purchasedAt\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query CollectionsForUser {\n    videoTemplateCollections {\n      slug\n      displayNameShort\n    }\n  }\n',
): (typeof documents)['\n  query CollectionsForUser {\n    videoTemplateCollections {\n      slug\n      displayNameShort\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SelectedCollectionDetails($collectionSlug: String) {\n    videoTemplateCollectionBySlug(slug: $collectionSlug) {\n      slug\n      displayNameShort\n      displayName\n      shortDescription\n    }\n  }\n',
): (typeof documents)['\n  query SelectedCollectionDetails($collectionSlug: String) {\n    videoTemplateCollectionBySlug(slug: $collectionSlug) {\n      slug\n      displayNameShort\n      displayName\n      shortDescription\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AllTemplateBrowserFilters {\n    videoTemplateVariants {\n      availableFilters {\n        displayDuration\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AllTemplateBrowserFilters {\n    videoTemplateVariants {\n      availableFilters {\n        displayDuration\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TemplateBrowserVariantFragment on VideoTemplateVariantRelayNode {\n    id\n    compoundSlug\n    omniGroupSlug\n    displayName\n    displayDuration\n    previewVideoUrl\n    thumbnailImageUrl\n    width\n    height\n    price\n    licensing\n    videoTemplate {\n      permissions\n    }\n  }\n',
): (typeof documents)['\n  fragment TemplateBrowserVariantFragment on VideoTemplateVariantRelayNode {\n    id\n    compoundSlug\n    omniGroupSlug\n    displayName\n    displayDuration\n    previewVideoUrl\n    thumbnailImageUrl\n    width\n    height\n    price\n    licensing\n    videoTemplate {\n      permissions\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query TemplateBrowserVariants(\n    $collectionSlug: String\n    $length: [String]\n    $aspectRatio: [String]\n    $hasFootage: Boolean\n    $maxImageAssetCount: Float\n    $minImageAssetCount: Float\n    $orderBy: [String]\n    $first: Int\n    $after: String\n  ) {\n    videoTemplateVariants(\n      groupVariant_VariantGroup_Slug: $collectionSlug\n      displayDuration_In: $length\n      videoTemplate_HasFootage: $hasFootage\n      videoTemplate_ImageAssetCount_Lte: $maxImageAssetCount\n      videoTemplate_ImageAssetCount_Gte: $minImageAssetCount\n      aspectRatio_In: $aspectRatio\n      orderBy: $orderBy\n      first: $first\n      after: $after\n    ) {\n      totalCount\n      edges {\n        cursor\n        node {\n          ...TemplateBrowserVariantFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query TemplateBrowserVariants(\n    $collectionSlug: String\n    $length: [String]\n    $aspectRatio: [String]\n    $hasFootage: Boolean\n    $maxImageAssetCount: Float\n    $minImageAssetCount: Float\n    $orderBy: [String]\n    $first: Int\n    $after: String\n  ) {\n    videoTemplateVariants(\n      groupVariant_VariantGroup_Slug: $collectionSlug\n      displayDuration_In: $length\n      videoTemplate_HasFootage: $hasFootage\n      videoTemplate_ImageAssetCount_Lte: $maxImageAssetCount\n      videoTemplate_ImageAssetCount_Gte: $minImageAssetCount\n      aspectRatio_In: $aspectRatio\n      orderBy: $orderBy\n      first: $first\n      after: $after\n    ) {\n      totalCount\n      edges {\n        cursor\n        node {\n          ...TemplateBrowserVariantFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountVideoLibrariesDetail($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      videoLibraries {\n        edges {\n          node {\n            ...CoreVideoLibraryFields\n          }\n        }\n      }\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            videoAssetLibraries {\n              edges {\n                node {\n                  ...CoreVideoLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountVideoLibrariesDetail($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      videoLibraries {\n        edges {\n          node {\n            ...CoreVideoLibraryFields\n          }\n        }\n      }\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            videoAssetLibraries {\n              edges {\n                node {\n                  ...CoreVideoLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BusinessVideoDetailsByGUID($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n\n      videoLibraries {\n        edges {\n          node {\n            ...CoreVideoLibraryFields\n          }\n        }\n      }\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BusinessVideoDetailsByGUID($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n\n      videoLibraries {\n        edges {\n          node {\n            ...CoreVideoLibraryFields\n          }\n        }\n      }\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountStockVideoLibraryAssetKeys($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      stockVideoLibraries {\n        edges {\n          node {\n            stockVideoLibraryVideos {\n              edges {\n                node {\n                  stockAsset {\n                    id\n                    vpsKey\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountStockVideoLibraryAssetKeys($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      stockVideoLibraries {\n        edges {\n          node {\n            stockVideoLibraryVideos {\n              edges {\n                node {\n                  stockAsset {\n                    id\n                    vpsKey\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BusinessStockVideoLibraryAssetKeys($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      stockVideoLibraries {\n        edges {\n          node {\n            stockVideoLibraryVideos {\n              edges {\n                node {\n                  stockAsset {\n                    id\n                    vpsKey\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BusinessStockVideoLibraryAssetKeys($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      stockVideoLibraries {\n        edges {\n          node {\n            stockVideoLibraryVideos {\n              edges {\n                node {\n                  stockAsset {\n                    id\n                    vpsKey\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateAccountVideoLibraryVideo($input: CreateAccountVideoLibraryVideoMutationInput!) {\n    createAccountVideoLibraryVideo(input: $input) {\n      videoLibraryVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAccountVideoLibraryVideo($input: CreateAccountVideoLibraryVideoMutationInput!) {\n    createAccountVideoLibraryVideo(input: $input) {\n      videoLibraryVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateBusinessVideoLibraryVideo($input: CreateBusinessVideoLibraryVideoMutationInput!) {\n    createBusinessVideoLibraryVideo(input: $input) {\n      videoLibraryVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateBusinessVideoLibraryVideo($input: CreateBusinessVideoLibraryVideoMutationInput!) {\n    createBusinessVideoLibraryVideo(input: $input) {\n      videoLibraryVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateVideoLibraryVideo($input: UpdateVideoLibraryVideoMutationInput!) {\n    updateVideoLibraryVideo(input: $input) {\n      updatedVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVideoLibraryVideo($input: UpdateVideoLibraryVideoMutationInput!) {\n    updateVideoLibraryVideo(input: $input) {\n      updatedVideo {\n        ...CoreVideoLibraryVideoFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AccountAudioAssetFields on AccountAudioAssetNode {\n    id\n    guid\n    uploadKey\n    length\n    displayName\n    source\n  }\n',
): (typeof documents)['\n  fragment AccountAudioAssetFields on AccountAudioAssetNode {\n    id\n    guid\n    uploadKey\n    length\n    displayName\n    source\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountAudioAssetForUploadKey($uploadKey: String!) {\n    accountAudioAssetForUploadKey(uploadKey: $uploadKey) {\n      ...AccountAudioAssetFields\n    }\n  }\n',
): (typeof documents)['\n  query AccountAudioAssetForUploadKey($uploadKey: String!) {\n    accountAudioAssetForUploadKey(uploadKey: $uploadKey) {\n      ...AccountAudioAssetFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GetOrCreateAccountColorLibrary($input: GetOrCreateAccountColorLibraryMutationInput!) {\n    getOrCreateAccountColorLibrary(input: $input) {\n      colorLibrary {\n        ...ColorLibraryFields\n      }\n      didCreate\n    }\n  }\n',
): (typeof documents)['\n  mutation GetOrCreateAccountColorLibrary($input: GetOrCreateAccountColorLibraryMutationInput!) {\n    getOrCreateAccountColorLibrary(input: $input) {\n      colorLibrary {\n        ...ColorLibraryFields\n      }\n      didCreate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GetOrCreateBusinessColorLibrary($input: GetOrCreateBusinessColorLibraryMutationInput!) {\n    getOrCreateBusinessColorLibrary(input: $input) {\n      colorLibrary {\n        ...ColorLibraryFields\n      }\n      didCreate\n    }\n  }\n',
): (typeof documents)['\n  mutation GetOrCreateBusinessColorLibrary($input: GetOrCreateBusinessColorLibraryMutationInput!) {\n    getOrCreateBusinessColorLibrary(input: $input) {\n      colorLibrary {\n        ...ColorLibraryFields\n      }\n      didCreate\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateOrCreateColorLibraryColor($input: UpdateOrCreateColorLibraryColorMutationInput!) {\n    updateOrCreateColorLibraryColor(input: $input) {\n      color {\n        ...ColorFields\n      }\n      updatedColorLibrary {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateOrCreateColorLibraryColor($input: UpdateOrCreateColorLibraryColorMutationInput!) {\n    updateOrCreateColorLibraryColor(input: $input) {\n      color {\n        ...ColorFields\n      }\n      updatedColorLibrary {\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteColorLibraryColor($input: DeleteColorLibraryColorMutationInput!) {\n    deleteColorLibraryColor(input: $input) {\n      removedColorId\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteColorLibraryColor($input: DeleteColorLibraryColorMutationInput!) {\n    deleteColorLibraryColor(input: $input) {\n      removedColorId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ColorFields on ColorLibraryColorNode {\n    id\n    order\n    displayName\n    hexCode\n    updatedAt\n  }\n',
): (typeof documents)['\n  fragment ColorFields on ColorLibraryColorNode {\n    id\n    order\n    displayName\n    hexCode\n    updatedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ColorLibraryFields on ColorLibraryNode {\n    id\n    guid\n    displayName\n    colors {\n      edges {\n        node {\n          ...ColorFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ColorLibraryFields on ColorLibraryNode {\n    id\n    guid\n    displayName\n    colors {\n      edges {\n        node {\n          ...ColorFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ColorLibrariesForAccount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n      colorLibraries {\n        edges {\n          node {\n            ...ColorLibraryFields\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            colorLibraries {\n              edges {\n                node {\n                  ...ColorLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ColorLibrariesForAccount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n      colorLibraries {\n        edges {\n          node {\n            ...ColorLibraryFields\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            colorLibraries {\n              edges {\n                node {\n                  ...ColorLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ColorLibrariesForBusiness($businessGUID: String) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      businessName\n      colorLibraries {\n        edges {\n          node {\n            ...ColorLibraryFields\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ColorLibrariesForBusiness($businessGUID: String) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      businessName\n      colorLibraries {\n        edges {\n          node {\n            ...ColorLibraryFields\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GetOrCreateDefaultAccountFontLibrary(\n    $input: GetOrCreateDefaultAccountFontLibraryMutationInput!\n  ) {\n    getOrCreateDefaultAccountFontLibrary(input: $input) {\n      fontLibrary {\n        ...FontLibraryFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation GetOrCreateDefaultAccountFontLibrary(\n    $input: GetOrCreateDefaultAccountFontLibraryMutationInput!\n  ) {\n    getOrCreateDefaultAccountFontLibrary(input: $input) {\n      fontLibrary {\n        ...FontLibraryFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GetOrCreateDefaultBusinessFontLibrary(\n    $input: GetOrCreateDefaultBusinessFontLibraryMutationInput!\n  ) {\n    getOrCreateDefaultBusinessFontLibrary(input: $input) {\n      fontLibrary {\n        ...FontLibraryFields\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation GetOrCreateDefaultBusinessFontLibrary(\n    $input: GetOrCreateDefaultBusinessFontLibraryMutationInput!\n  ) {\n    getOrCreateDefaultBusinessFontLibrary(input: $input) {\n      fontLibrary {\n        ...FontLibraryFields\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddFontToFontLibrary($input: AddFontToFontLibraryMutationInput!) {\n    addFontToFontLibrary(input: $input) {\n      createdFontLibraryFont {\n        ...FontLibraryFontFields\n      }\n      updatedFontLibrary {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddFontToFontLibrary($input: AddFontToFontLibraryMutationInput!) {\n    addFontToFontLibrary(input: $input) {\n      createdFontLibraryFont {\n        ...FontLibraryFontFields\n      }\n      updatedFontLibrary {\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveFontFromFontLibrary($input: RemoveFontFromFontLibraryMutationInput!) {\n    removeFontFromFontLibrary(input: $input) {\n      removedFontLibraryFontId\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveFontFromFontLibrary($input: RemoveFontFromFontLibraryMutationInput!) {\n    removeFontFromFontLibrary(input: $input) {\n      removedFontLibraryFontId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BFSFontVariantFields on BFSFontVariant {\n    uuid\n    isItalic\n    weightIndex\n  }\n',
): (typeof documents)['\n  fragment BFSFontVariantFields on BFSFontVariant {\n    uuid\n    isItalic\n    weightIndex\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment TemplateFontFields on TemplateFontNode {\n    id\n    bfsUuid\n    fontFamilyName\n    variants {\n      ...BFSFontVariantFields\n    }\n  }\n',
): (typeof documents)['\n  fragment TemplateFontFields on TemplateFontNode {\n    id\n    bfsUuid\n    fontFamilyName\n    variants {\n      ...BFSFontVariantFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FontLibraryFontFields on FontLibraryFontNode {\n    id\n    guid\n    displayName\n    order\n    updatedAt\n    font {\n      ...TemplateFontFields\n    }\n  }\n',
): (typeof documents)['\n  fragment FontLibraryFontFields on FontLibraryFontNode {\n    id\n    guid\n    displayName\n    order\n    updatedAt\n    font {\n      ...TemplateFontFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment FontLibraryFields on FontLibraryNode {\n    id\n    guid\n    displayName\n    fonts {\n      edges {\n        node {\n          ...FontLibraryFontFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment FontLibraryFields on FontLibraryNode {\n    id\n    guid\n    displayName\n    fonts {\n      edges {\n        node {\n          ...FontLibraryFontFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FontLibrariesForBusiness($businessGUID: String) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      businessName\n      guid\n      fontLibraries {\n        edges {\n          node {\n            ...FontLibraryFields\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FontLibrariesForBusiness($businessGUID: String) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      businessName\n      guid\n      fontLibraries {\n        edges {\n          node {\n            ...FontLibraryFields\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query FontLibrariesForAccount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      displayName\n      guid\n      fontLibraries {\n        edges {\n          node {\n            ...FontLibraryFields\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            fontLibraries {\n              edges {\n                node {\n                  ...FontLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query FontLibrariesForAccount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      displayName\n      guid\n      fontLibraries {\n        edges {\n          node {\n            ...FontLibraryFields\n          }\n        }\n      }\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            fontLibraries {\n              edges {\n                node {\n                  ...FontLibraryFields\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GlobalFontLibraries {\n    globalFontLibraries {\n      edges {\n        node {\n          ...FontLibraryFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GlobalFontLibraries {\n    globalFontLibraries {\n      edges {\n        node {\n          ...FontLibraryFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BusinessImageData($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalImageCount\n      logoImage {\n        id\n        guid\n        baseUrl\n        updatedAt\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BusinessImageData($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalImageCount\n      logoImage {\n        id\n        guid\n        baseUrl\n        updatedAt\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountImageLibraries($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n      displayName\n\n      imageLibraries {\n        edges {\n          node {\n            ...ImageLibraryFragment\n          }\n        }\n      }\n\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            imageLibraries {\n              edges {\n                node {\n                  ...ImageLibraryFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountImageLibraries($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n      displayName\n\n      imageLibraries {\n        edges {\n          node {\n            ...ImageLibraryFragment\n          }\n        }\n      }\n\n      accountGroups {\n        edges {\n          node {\n            id\n            displayName\n            imageLibraries {\n              edges {\n                node {\n                  ...ImageLibraryFragment\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BusinessImageLibraries($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalImageCount\n      logoImage {\n        id\n        guid\n        baseUrl\n        updatedAt\n      }\n      businessName\n\n      imageLibraries {\n        edges {\n          node {\n            ...ImageLibraryFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query BusinessImageLibraries($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalImageCount\n      logoImage {\n        id\n        guid\n        baseUrl\n        updatedAt\n      }\n      businessName\n\n      imageLibraries {\n        edges {\n          node {\n            ...ImageLibraryFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateUserVideo($input: UpdateUserVideoMutationInput!) {\n    updateUserVideo(input: $input) {\n      updatedUserVideo {\n        id\n        guid\n        # Return updated values for any fields which can be updated in this mutation\n        # so we can update the cache with the new values!\n        title\n        videoConfiguration\n        business {\n          ...CoreBusinessDetailsFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateUserVideo($input: UpdateUserVideoMutationInput!) {\n    updateUserVideo(input: $input) {\n      updatedUserVideo {\n        id\n        guid\n        # Return updated values for any fields which can be updated in this mutation\n        # so we can update the cache with the new values!\n        title\n        videoConfiguration\n        business {\n          ...CoreBusinessDetailsFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation DeleteUserVideo($input: DeleteUserVideoMutationInput!) {\n    deleteUserVideo(input: $input) {\n      deletedUserVideo {\n        id\n        # Include guid because we use that field for user videos' cache ids\n        guid\n      }\n    }\n  }\n",
): (typeof documents)["\n  mutation DeleteUserVideo($input: DeleteUserVideoMutationInput!) {\n    deleteUserVideo(input: $input) {\n      deletedUserVideo {\n        id\n        # Include guid because we use that field for user videos' cache ids\n        guid\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateUserVideo($input: CreateUserVideoMutationInput!) {\n    createUserVideo(input: $input) {\n      userVideo {\n        id\n        guid\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateUserVideo($input: CreateUserVideoMutationInput!) {\n    createUserVideo(input: $input) {\n      userVideo {\n        id\n        guid\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RenderUserVideo($input: RenderUserVideoMutationInput!) {\n    renderUserVideo(input: $input) {\n      userVideo {\n        ...CoreUserVideoFragment\n        ...UserVideoAccountPageFieldsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RenderUserVideo($input: RenderUserVideoMutationInput!) {\n    renderUserVideo(input: $input) {\n      userVideo {\n        ...CoreUserVideoFragment\n        ...UserVideoAccountPageFieldsFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateUserVideoApprovalRequest($input: CreateUserVideoApprovalRequestMutationInput!) {\n    createUserVideoApprovalRequest(input: $input) {\n      userVideo {\n        id\n        guid\n        userVideoApprovals {\n          ...UserVideoApprovalFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateUserVideoApprovalRequest($input: CreateUserVideoApprovalRequestMutationInput!) {\n    createUserVideoApprovalRequest(input: $input) {\n      userVideo {\n        id\n        guid\n        userVideoApprovals {\n          ...UserVideoApprovalFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation ApproveUserVideo($input: ApproveUserVideoMutationInput!) {\n    approveUserVideo(input: $input) {\n      userVideo {\n        id\n        guid\n        userVideoApprovals {\n          ...UserVideoApprovalFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation ApproveUserVideo($input: ApproveUserVideoMutationInput!) {\n    approveUserVideo(input: $input) {\n      userVideo {\n        id\n        guid\n        userVideoApprovals {\n          ...UserVideoApprovalFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query MostRecentlyEditedUserVideoInfo($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      lastEditedUserVideo: userVideos(first: 1, orderBy: "-last_edited_by_user") {\n        edges {\n          node {\n            id\n            guid\n            lastEditedByUser\n          }\n        }\n      }\n      lastPurchasedUserVideo: userVideos(first: 1, orderBy: "-purchased_at") {\n        edges {\n          node {\n            id\n            guid\n            purchasedAt\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query MostRecentlyEditedUserVideoInfo($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      lastEditedUserVideo: userVideos(first: 1, orderBy: "-last_edited_by_user") {\n        edges {\n          node {\n            id\n            guid\n            lastEditedByUser\n          }\n        }\n      }\n      lastPurchasedUserVideo: userVideos(first: 1, orderBy: "-purchased_at") {\n        edges {\n          node {\n            id\n            guid\n            purchasedAt\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserVideoRenderedVideo on RenderedVideoNode {\n    id\n    renderUrl\n    renderFormat\n    renderStatus\n    renderSize\n    hasWatermark\n  }\n',
): (typeof documents)['\n  fragment UserVideoRenderedVideo on RenderedVideoNode {\n    id\n    renderUrl\n    renderFormat\n    renderStatus\n    renderSize\n    hasWatermark\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserVideoRenders($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      currentConfiguredVideo {\n        id\n        renderStatus\n        renderedVideos {\n          edges {\n            node {\n              ...UserVideoRenderedVideo\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UserVideoRenders($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      currentConfiguredVideo {\n        id\n        renderStatus\n        renderedVideos {\n          edges {\n            node {\n              ...UserVideoRenderedVideo\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountPartners($orderBy: [String]) {\n    partners(orderBy: $orderBy) {\n      edges {\n        node {\n          id\n          guid\n          name\n          slug\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountPartners($orderBy: [String]) {\n    partners(orderBy: $orderBy) {\n      edges {\n        node {\n          id\n          guid\n          name\n          slug\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SelectedAccountPartner($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      partner {\n        id\n        guid\n        name\n        slug\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SelectedAccountPartner($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      partner {\n        id\n        guid\n        name\n        slug\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ManagedAccountList(\n    $after: String\n    $first: Int\n    $orderBy: [String]\n    $isActive: Boolean\n    $accountGroupGUID: String\n    $hasNoAccountGroup: Boolean\n    $role: String\n    $searchString: String\n    $partnerGUID: String\n  ) {\n    accounts(\n      after: $after\n      first: $first\n      orderBy: $orderBy\n      isActive: $isActive\n      accountGroups_Guid: $accountGroupGUID\n      accountGroups_Isnull: $hasNoAccountGroup\n      role: $role\n      searchString: $searchString\n      partner_Guid: $partnerGUID\n    ) {\n      pageInfo {\n        endCursor\n      }\n      totalCount\n\n      edges {\n        node {\n          ...CoreAccountFields\n          totalVideosCreated\n          totalVideosCompleted\n          ...AccountAccountGroupFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ManagedAccountList(\n    $after: String\n    $first: Int\n    $orderBy: [String]\n    $isActive: Boolean\n    $accountGroupGUID: String\n    $hasNoAccountGroup: Boolean\n    $role: String\n    $searchString: String\n    $partnerGUID: String\n  ) {\n    accounts(\n      after: $after\n      first: $first\n      orderBy: $orderBy\n      isActive: $isActive\n      accountGroups_Guid: $accountGroupGUID\n      accountGroups_Isnull: $hasNoAccountGroup\n      role: $role\n      searchString: $searchString\n      partner_Guid: $partnerGUID\n    ) {\n      pageInfo {\n        endCursor\n      }\n      totalCount\n\n      edges {\n        node {\n          ...CoreAccountFields\n          totalVideosCreated\n          totalVideosCompleted\n          ...AccountAccountGroupFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RefreshBusinessStockVideoLibrary($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalVideoCount\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RefreshBusinessStockVideoLibrary($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalVideoCount\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query RefreshAccountStockVideoLibrary($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query RefreshAccountStockVideoLibrary($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      guid\n\n      stockVideoLibraries {\n        edges {\n          node {\n            ...StockVideoLibraryFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query Partners {\n    partners {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          slug\n          emailDomain\n          canUsersPurchase\n          hasVoiceOver\n          maxActiveUsers\n          maxMonthlyVideos\n          hasUnlimited\n          shouldHideBusinessPersonalization\n          isTvOnlyIntegration\n          accountGroups {\n            edges {\n              node {\n                id\n                guid\n                displayName\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query Partners {\n    partners {\n      totalCount\n      edges {\n        node {\n          id\n          name\n          slug\n          emailDomain\n          canUsersPurchase\n          hasVoiceOver\n          maxActiveUsers\n          maxMonthlyVideos\n          hasUnlimited\n          shouldHideBusinessPersonalization\n          isTvOnlyIntegration\n          accountGroups {\n            edges {\n              node {\n                id\n                guid\n                displayName\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PartnerStatsBySlug($slug: String!, $startDate: DateTime!, $endDate: DateTime!) {\n    partners(slug: $slug) {\n      totalCount\n      edges {\n        node {\n          id\n          visitCount(start: $startDate, end: $endDate)\n          draftVideoCount(start: $startDate, end: $endDate)\n          generatedVideoCount(start: $startDate, end: $endDate)\n          purchasedVideoCount(start: $startDate, end: $endDate)\n          renderedVideoCount(start: $startDate, end: $endDate)\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PartnerStatsBySlug($slug: String!, $startDate: DateTime!, $endDate: DateTime!) {\n    partners(slug: $slug) {\n      totalCount\n      edges {\n        node {\n          id\n          visitCount(start: $startDate, end: $endDate)\n          draftVideoCount(start: $startDate, end: $endDate)\n          generatedVideoCount(start: $startDate, end: $endDate)\n          purchasedVideoCount(start: $startDate, end: $endDate)\n          renderedVideoCount(start: $startDate, end: $endDate)\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PartnerAccountGroupStatsBySlug(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n  ) {\n    partners(slug: $slug) {\n      totalCount\n      edges {\n        node {\n          id\n          accountGroups(guid: $accountGroupGUID) {\n            edges {\n              node {\n                id\n                visitCount(start: $startDate, end: $endDate)\n                draftVideoCount(start: $startDate, end: $endDate)\n                generatedVideoCount(start: $startDate, end: $endDate)\n                purchasedVideoCount(start: $startDate, end: $endDate)\n                renderedVideoCount(start: $startDate, end: $endDate)\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query PartnerAccountGroupStatsBySlug(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n  ) {\n    partners(slug: $slug) {\n      totalCount\n      edges {\n        node {\n          id\n          accountGroups(guid: $accountGroupGUID) {\n            edges {\n              node {\n                id\n                visitCount(start: $startDate, end: $endDate)\n                draftVideoCount(start: $startDate, end: $endDate)\n                generatedVideoCount(start: $startDate, end: $endDate)\n                purchasedVideoCount(start: $startDate, end: $endDate)\n                renderedVideoCount(start: $startDate, end: $endDate)\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PartnerUsageByAccount(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n  ) {\n    partnerUsageByAccount(\n      slug: $slug\n      start: $startDate\n      end: $endDate\n      accountGroupGUID: $accountGroupGUID\n    ) {\n      id\n      emailAddress\n      displayName\n      visitCount\n      draftVideoCount\n      purchasedVideoCount\n      generatedVideoCount\n      renderedVideoCount\n    }\n  }\n',
): (typeof documents)['\n  query PartnerUsageByAccount(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n  ) {\n    partnerUsageByAccount(\n      slug: $slug\n      start: $startDate\n      end: $endDate\n      accountGroupGUID: $accountGroupGUID\n    ) {\n      id\n      emailAddress\n      displayName\n      visitCount\n      draftVideoCount\n      purchasedVideoCount\n      generatedVideoCount\n      renderedVideoCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PartnerUsageByAccountGroup($slug: String!, $startDate: DateTime!, $endDate: DateTime!) {\n    partnerUsageByAccountGroup(slug: $slug, start: $startDate, end: $endDate) {\n      groupName\n      parentName\n      visitCount\n      draftVideoCount\n      purchasedVideoCount\n    }\n  }\n',
): (typeof documents)['\n  query PartnerUsageByAccountGroup($slug: String!, $startDate: DateTime!, $endDate: DateTime!) {\n    partnerUsageByAccountGroup(slug: $slug, start: $startDate, end: $endDate) {\n      groupName\n      parentName\n      visitCount\n      draftVideoCount\n      purchasedVideoCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PartnerVideoDetails(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n    $isPurchased: Boolean!\n  ) {\n    partnerVideosByAccount(\n      slug: $slug\n      purchased: $isPurchased\n      start: $startDate\n      end: $endDate\n      accountGroupGUID: $accountGroupGUID\n    ) {\n      accountId\n      accountEmailAddress\n      accountDisplayName\n      videoCreatedAt\n      videoPurchasedAt\n      videoTitle\n      variantName\n      brandName\n      aspectRatio\n      duration\n      licensing\n      videoGuid\n    }\n  }\n',
): (typeof documents)['\n  query PartnerVideoDetails(\n    $slug: String!\n    $startDate: DateTime!\n    $endDate: DateTime!\n    $accountGroupGUID: String\n    $isPurchased: Boolean!\n  ) {\n    partnerVideosByAccount(\n      slug: $slug\n      purchased: $isPurchased\n      start: $startDate\n      end: $endDate\n      accountGroupGUID: $accountGroupGUID\n    ) {\n      accountId\n      accountEmailAddress\n      accountDisplayName\n      videoCreatedAt\n      videoPurchasedAt\n      videoTitle\n      variantName\n      brandName\n      aspectRatio\n      duration\n      licensing\n      videoGuid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateAutofillResultVideoDescriptor(\n    $requestGUID: String!\n    $videoDescriptorJSON: String!\n  ) {\n    updateAutofillResult(\n      input: { requestGuid: $requestGUID, videoDescriptorJson: $videoDescriptorJSON }\n    ) {\n      updatedAutofillResult {\n        id\n        videoDescriptor\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateAutofillResultVideoDescriptor(\n    $requestGUID: String!\n    $videoDescriptorJSON: String!\n  ) {\n    updateAutofillResult(\n      input: { requestGuid: $requestGUID, videoDescriptorJson: $videoDescriptorJSON }\n    ) {\n      updatedAutofillResult {\n        id\n        videoDescriptor\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateAutofillFeedback(\n    $requestGUID: String!\n    $rating: String!\n    $feedbackText: String!\n    $feedbackChipGUIDs: [String!]\n  ) {\n    createAutofillFeedback(\n      input: {\n        autofillRequestGuid: $requestGUID\n        rating: $rating\n        feedbackText: $feedbackText\n        feedbackChipGuids: $feedbackChipGUIDs\n      }\n    ) {\n      createdAutofillFeedback {\n        guid\n        rating\n        feedbackText\n        feedbackChips {\n          edges {\n            node {\n              guid\n              text\n              suggestion\n              suggestionTitle\n              category {\n                guid\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateAutofillFeedback(\n    $requestGUID: String!\n    $rating: String!\n    $feedbackText: String!\n    $feedbackChipGUIDs: [String!]\n  ) {\n    createAutofillFeedback(\n      input: {\n        autofillRequestGuid: $requestGUID\n        rating: $rating\n        feedbackText: $feedbackText\n        feedbackChipGuids: $feedbackChipGUIDs\n      }\n    ) {\n      createdAutofillFeedback {\n        guid\n        rating\n        feedbackText\n        feedbackChips {\n          edges {\n            node {\n              guid\n              text\n              suggestion\n              suggestionTitle\n              category {\n                guid\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateAutofillFeedback(\n    $requestGUID: String!\n    $rating: String!\n    $feedbackText: String!\n    $feedbackChipGUIDs: [String!]\n  ) {\n    updateAutofillFeedback(\n      input: {\n        autofillRequestGuid: $requestGUID\n        rating: $rating\n        feedbackText: $feedbackText\n        feedbackChipGuids: $feedbackChipGUIDs\n      }\n    ) {\n      updatedAutofillFeedback {\n        guid\n        rating\n        feedbackText\n        feedbackChips {\n          edges {\n            node {\n              guid\n              text\n              suggestion\n              suggestionTitle\n              category {\n                guid\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateAutofillFeedback(\n    $requestGUID: String!\n    $rating: String!\n    $feedbackText: String!\n    $feedbackChipGUIDs: [String!]\n  ) {\n    updateAutofillFeedback(\n      input: {\n        autofillRequestGuid: $requestGUID\n        rating: $rating\n        feedbackText: $feedbackText\n        feedbackChipGuids: $feedbackChipGUIDs\n      }\n    ) {\n      updatedAutofillFeedback {\n        guid\n        rating\n        feedbackText\n        feedbackChips {\n          edges {\n            node {\n              guid\n              text\n              suggestion\n              suggestionTitle\n              category {\n                guid\n                name\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment AutofillResultFields on UserVideoAutofillResultNode {\n    id\n    pk\n    createdAt\n    requestGuid\n    requestSource\n    userInstructions\n    vibe\n    videoDescriptor\n    voiceover\n    userVideo {\n      id\n      guid\n      title\n      videoConfiguration\n      purchasedAt\n    }\n    videoTemplateVariant {\n      id\n      compoundSlug\n      displayDuration\n      displayName\n      height\n      width\n    }\n    business {\n      id\n      guid\n      businessName\n    }\n  }\n',
): (typeof documents)['\n  fragment AutofillResultFields on UserVideoAutofillResultNode {\n    id\n    pk\n    createdAt\n    requestGuid\n    requestSource\n    userInstructions\n    vibe\n    videoDescriptor\n    voiceover\n    userVideo {\n      id\n      guid\n      title\n      videoConfiguration\n      purchasedAt\n    }\n    videoTemplateVariant {\n      id\n      compoundSlug\n      displayDuration\n      displayName\n      height\n      width\n    }\n    business {\n      id\n      guid\n      businessName\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutofillResultsForAccount(\n    $accountGUID: String!\n    $maxAgeDays: Float\n    $requestSource: String\n  ) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      autofillResults(maxAgeDays: $maxAgeDays, requestSource: $requestSource) {\n        edges {\n          node {\n            ...AutofillResultFields\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AutofillResultsForAccount(\n    $accountGUID: String!\n    $maxAgeDays: Float\n    $requestSource: String\n  ) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      autofillResults(maxAgeDays: $maxAgeDays, requestSource: $requestSource) {\n        edges {\n          node {\n            ...AutofillResultFields\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutofillResultByRequestGUID($requestGUID: String!) {\n    autofillResultByRequestGuid(requestGuid: $requestGUID) {\n      ...AutofillResultFields\n    }\n  }\n',
): (typeof documents)['\n  query AutofillResultByRequestGUID($requestGUID: String!) {\n    autofillResultByRequestGuid(requestGuid: $requestGUID) {\n      ...AutofillResultFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutofillResultFeedback($requestGUID: String) {\n    autofillResultByRequestGuid(requestGuid: $requestGUID) {\n      autofillFeedback {\n        edges {\n          node {\n            guid\n            rating\n            feedbackText\n            feedbackChips {\n              edges {\n                node {\n                  guid\n                  text\n                  suggestion\n                  suggestionTitle\n                  category {\n                    guid\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AutofillResultFeedback($requestGUID: String) {\n    autofillResultByRequestGuid(requestGuid: $requestGUID) {\n      autofillFeedback {\n        edges {\n          node {\n            guid\n            rating\n            feedbackText\n            feedbackChips {\n              edges {\n                node {\n                  guid\n                  text\n                  suggestion\n                  suggestionTitle\n                  category {\n                    guid\n                    name\n                  }\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AutofillFeedbackChips {\n    autofillFeedbackChipCategories {\n      guid\n      name\n      feedbackChips {\n        edges {\n          node {\n            guid\n            text\n            suggestion\n            suggestionTitle\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AutofillFeedbackChips {\n    autofillFeedbackChipCategories {\n      guid\n      name\n      feedbackChips {\n        edges {\n          node {\n            guid\n            text\n            suggestion\n            suggestionTitle\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BusinessSearchBusinessDataUpdate on BusinessRelayNode {\n    id\n    guid\n    pk\n    businessName\n    contactPhone\n    streetAddress1\n    city\n    state\n    postalCode\n    businessAbout\n    industryName\n    websiteUrl\n  }\n',
): (typeof documents)['\n  fragment BusinessSearchBusinessDataUpdate on BusinessRelayNode {\n    id\n    guid\n    pk\n    businessName\n    contactPhone\n    streetAddress1\n    city\n    state\n    postalCode\n    businessAbout\n    industryName\n    websiteUrl\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BusinessSearchColorsCreated on ColorLibraryColorNode {\n    ...ColorLibraryColorFragment\n    colorLibrary {\n      id\n      guid\n    }\n  }\n',
): (typeof documents)['\n  fragment BusinessSearchColorsCreated on ColorLibraryColorNode {\n    ...ColorLibraryColorFragment\n    colorLibrary {\n      id\n      guid\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment BusinessSearchImagesCreated on ImageLibraryImageNode {\n    ...ImageLibraryImageFragment\n    imageLibrary {\n      id\n      pk\n    }\n  }\n',
): (typeof documents)['\n  fragment BusinessSearchImagesCreated on ImageLibraryImageNode {\n    ...ImageLibraryImageFragment\n    imageLibrary {\n      id\n      pk\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CoreBusinessDetailsFragment on BusinessRelayNode {\n    id\n    pk\n    createdAt\n    guid\n    isVisible\n    businessName\n    businessAbout\n    websiteUrl\n    streetAddress1\n    streetAddress2\n    city\n    state\n    postalCode\n    contactPhone\n    industryName\n    totalImageCount\n    totalVideoCount\n    logoImage {\n      ...ImageLibraryImageFragment\n    }\n    colorLibraries {\n      edges {\n        node {\n          ...ColorLibraryFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment CoreBusinessDetailsFragment on BusinessRelayNode {\n    id\n    pk\n    createdAt\n    guid\n    isVisible\n    businessName\n    businessAbout\n    websiteUrl\n    streetAddress1\n    streetAddress2\n    city\n    state\n    postalCode\n    contactPhone\n    industryName\n    totalImageCount\n    totalVideoCount\n    logoImage {\n      ...ImageLibraryImageFragment\n    }\n    colorLibraries {\n      edges {\n        node {\n          ...ColorLibraryFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateBusiness($input: CreateBusinessMutationInput!) {\n    createBusiness(input: $input) {\n      createdBusiness {\n        ...CoreBusinessDetailsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateBusiness($input: CreateBusinessMutationInput!) {\n    createBusiness(input: $input) {\n      createdBusiness {\n        ...CoreBusinessDetailsFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateBusiness($input: UpdateBusinessMutationInput!) {\n    updateBusiness(input: $input) {\n      updatedBusiness {\n        ...CoreBusinessDetailsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateBusiness($input: UpdateBusinessMutationInput!) {\n    updateBusiness(input: $input) {\n      updatedBusiness {\n        ...CoreBusinessDetailsFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation DeleteBusiness($input: DeleteBusinessMutationInput!) {\n    deleteBusiness(input: $input) {\n      deletedBusinessId\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteBusiness($input: DeleteBusinessMutationInput!) {\n    deleteBusiness(input: $input) {\n      deletedBusinessId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BusinessDetailsByGUID($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      ...CoreBusinessDetailsFragment\n    }\n  }\n',
): (typeof documents)['\n  query BusinessDetailsByGUID($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      ...CoreBusinessDetailsFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ListAllBusinessProfilesForAccount($accountGUID: String!, $orderBy: [String]) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      businesses(orderBy: $orderBy) {\n        edges {\n          node {\n            ...CoreBusinessDetailsFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query ListAllBusinessProfilesForAccount($accountGUID: String!, $orderBy: [String]) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      businesses(orderBy: $orderBy) {\n        edges {\n          node {\n            ...CoreBusinessDetailsFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query BusinessVideoCount($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalVideoCount\n    }\n  }\n',
): (typeof documents)['\n  query BusinessVideoCount($businessGUID: String!) {\n    businessByGuid(guid: $businessGUID) {\n      id\n      guid\n      totalVideoCount\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ColorLibraryColorFragment on ColorLibraryColorNode {\n    id\n    order\n    displayName\n    hexCode\n    updatedAt\n  }\n',
): (typeof documents)['\n  fragment ColorLibraryColorFragment on ColorLibraryColorNode {\n    id\n    order\n    displayName\n    hexCode\n    updatedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ColorLibraryFragment on ColorLibraryNode {\n    id\n    guid\n    displayName\n    colors {\n      edges {\n        node {\n          ...ColorLibraryColorFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ColorLibraryFragment on ColorLibraryNode {\n    id\n    guid\n    displayName\n    colors {\n      edges {\n        node {\n          ...ColorLibraryColorFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ImageLibraryImageFragment on ImageLibraryImageNode {\n    __typename\n    id\n    pk\n    guid\n    displayName\n    image\n    order\n    source\n    imageType\n    isFavorite\n    removedAt\n    updatedAt\n    lastUsedAt\n    baseUrl\n    imageWidth\n    imageHeight\n    upscaledImageUrl\n    stockAssetId\n    stockSearchId\n  }\n',
): (typeof documents)['\n  fragment ImageLibraryImageFragment on ImageLibraryImageNode {\n    __typename\n    id\n    pk\n    guid\n    displayName\n    image\n    order\n    source\n    imageType\n    isFavorite\n    removedAt\n    updatedAt\n    lastUsedAt\n    baseUrl\n    imageWidth\n    imageHeight\n    upscaledImageUrl\n    stockAssetId\n    stockSearchId\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ImageLibraryFragment on ImageLibraryNode {\n    id\n    pk\n    slug\n    displayName\n    images {\n      edges {\n        node {\n          ...ImageLibraryImageFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ImageLibraryFragment on ImageLibraryNode {\n    id\n    pk\n    slug\n    displayName\n    images {\n      edges {\n        node {\n          ...ImageLibraryImageFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GetOrCreateDefaultAccountImageLibrary(\n    $input: GetOrCreateDefaultAccountImageLibraryMutationInput!\n  ) {\n    getOrCreateDefaultAccountImageLibrary(input: $input) {\n      imageLibrary {\n        ...ImageLibraryFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation GetOrCreateDefaultAccountImageLibrary(\n    $input: GetOrCreateDefaultAccountImageLibraryMutationInput!\n  ) {\n    getOrCreateDefaultAccountImageLibrary(input: $input) {\n      imageLibrary {\n        ...ImageLibraryFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation GetOrCreateDefaultBusinessImageLibrary(\n    $input: GetOrCreateDefaultBusinessImageLibraryMutationInput!\n  ) {\n    getOrCreateDefaultBusinessImageLibrary(input: $input) {\n      imageLibrary {\n        ...ImageLibraryFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation GetOrCreateDefaultBusinessImageLibrary(\n    $input: GetOrCreateDefaultBusinessImageLibraryMutationInput!\n  ) {\n    getOrCreateDefaultBusinessImageLibrary(input: $input) {\n      imageLibrary {\n        ...ImageLibraryFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddImageToImageLibraryMutation($input: AddImageToImageLibraryMutationInput!) {\n    addImageToImageLibrary(input: $input) {\n      createdImageLibraryImage {\n        ...ImageLibraryImageFragment\n      }\n      updatedImageLibrary {\n        id\n        images {\n          edges {\n            node {\n              ...ImageLibraryImageFragment\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddImageToImageLibraryMutation($input: AddImageToImageLibraryMutationInput!) {\n    addImageToImageLibrary(input: $input) {\n      createdImageLibraryImage {\n        ...ImageLibraryImageFragment\n      }\n      updatedImageLibrary {\n        id\n        images {\n          edges {\n            node {\n              ...ImageLibraryImageFragment\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateImageLibraryImage($input: UpdateImageLibraryImageMutationInput!) {\n    updateImageLibraryImage(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateImageLibraryImage($input: UpdateImageLibraryImageMutationInput!) {\n    updateImageLibraryImage(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation UpdateImageLibraryImageLastUsedAt(\n    $input: UpdateImageLibraryImageLastUsedAtMutationInput!\n  ) {\n    updateImageLibraryImageLastUsedAt(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateImageLibraryImageLastUsedAt(\n    $input: UpdateImageLibraryImageLastUsedAtMutationInput!\n  ) {\n    updateImageLibraryImageLastUsedAt(input: $input) {\n      updatedImage {\n        ...ImageLibraryImageFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query StockImagesForImageKeys($imageKeys: [String!]!) {\n    stockImagesForImageKeys(imageKeys: $imageKeys) {\n      id\n      stockAssetId\n      stockSearchId\n    }\n  }\n',
): (typeof documents)['\n  query StockImagesForImageKeys($imageKeys: [String!]!) {\n    stockImagesForImageKeys(imageKeys: $imageKeys) {\n      id\n      stockAssetId\n      stockSearchId\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockVideoAssetFragment on StockVideoAssetNode {\n    __typename\n    id\n    source\n    sourceAssetId\n    width\n    height\n    length\n    vpsKey\n  }\n',
): (typeof documents)['\n  fragment StockVideoAssetFragment on StockVideoAssetNode {\n    __typename\n    id\n    source\n    sourceAssetId\n    width\n    height\n    length\n    vpsKey\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockVideoLibraryVideoFragment on StockVideoLibraryVideoNode {\n    __typename\n    id\n    guid\n    removedAt\n    updatedAt\n    stockAsset {\n      ...StockVideoAssetFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment StockVideoLibraryVideoFragment on StockVideoLibraryVideoNode {\n    __typename\n    id\n    guid\n    removedAt\n    updatedAt\n    stockAsset {\n      ...StockVideoAssetFragment\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment StockVideoLibraryFragment on StockVideoLibraryNode {\n    __typename\n    id\n    guid\n    displayName\n    stockVideoLibraryVideos {\n      __typename\n      edges {\n        __typename\n        node {\n          ...StockVideoLibraryVideoFragment\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment StockVideoLibraryFragment on StockVideoLibraryNode {\n    __typename\n    id\n    guid\n    displayName\n    stockVideoLibraryVideos {\n      __typename\n      edges {\n        __typename\n        node {\n          ...StockVideoLibraryVideoFragment\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation AddStockVideoToLibrary($input: AddStockVideoToLibraryMutationInput!) {\n    addStockVideoToLibrary(input: $input) {\n      createdStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n      createdStockVideoLibrary {\n        id\n        guid\n        displayName\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation AddStockVideoToLibrary($input: AddStockVideoToLibraryMutationInput!) {\n    addStockVideoToLibrary(input: $input) {\n      createdStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n      createdStockVideoLibrary {\n        id\n        guid\n        displayName\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RemoveStockVideoFromLibrary($input: RemoveStockVideoFromLibraryMutationInput!) {\n    removeStockVideoFromLibrary(input: $input) {\n      updatedStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RemoveStockVideoFromLibrary($input: RemoveStockVideoFromLibraryMutationInput!) {\n    removeStockVideoFromLibrary(input: $input) {\n      updatedStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation RestoreRemovedStockVideo($input: RestoreRemovedStockVideoToLibraryMutationInput!) {\n    restoreRemovedStockVideoToLibrary(input: $input) {\n      updatedStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation RestoreRemovedStockVideo($input: RestoreRemovedStockVideoToLibraryMutationInput!) {\n    restoreRemovedStockVideoToLibrary(input: $input) {\n      updatedStockVideoLibraryVideo {\n        ...StockVideoLibraryVideoFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CoreUserVideoFragment on UserVideoNode {\n    id\n    guid\n    title\n    createdAt\n    updatedAt\n    purchasedAt\n    lastEditedByUser\n    lastEditableDate\n    business {\n      id\n      guid\n      businessName\n    }\n    videoTemplateVariant {\n      id\n      guid\n      displayName\n      displayDuration\n      width\n      height\n      videoTemplate {\n        id\n        isWaymarkAuthorTemplate\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment CoreUserVideoFragment on UserVideoNode {\n    id\n    guid\n    title\n    createdAt\n    updatedAt\n    purchasedAt\n    lastEditedByUser\n    lastEditableDate\n    business {\n      id\n      guid\n      businessName\n    }\n    videoTemplateVariant {\n      id\n      guid\n      displayName\n      displayDuration\n      width\n      height\n      videoTemplate {\n        id\n        isWaymarkAuthorTemplate\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserVideoApprovalFragment on UserVideoApprovalNode {\n    id\n    guid\n    createdAt\n    fullName\n    approvedAt\n  }\n',
): (typeof documents)['\n  fragment UserVideoApprovalFragment on UserVideoApprovalNode {\n    id\n    guid\n    createdAt\n    fullName\n    approvedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserVideoAccountPageFieldsFragment on UserVideoNode {\n    ...CoreUserVideoFragment\n    currentConfiguredVideo {\n      id\n      thumbnailUrl\n    }\n    purchaseApproval {\n      id\n      guid\n      createdAt\n      approvedAt\n    }\n    userVideoApprovals {\n      ...UserVideoApprovalFragment\n    }\n    voiceOverProduct {\n      id\n      isDelivered\n    }\n    derivedUserVideos {\n      totalCount\n    }\n    originalUserVideo {\n      id\n      guid\n      title\n    }\n  }\n',
): (typeof documents)['\n  fragment UserVideoAccountPageFieldsFragment on UserVideoNode {\n    ...CoreUserVideoFragment\n    currentConfiguredVideo {\n      id\n      thumbnailUrl\n    }\n    purchaseApproval {\n      id\n      guid\n      createdAt\n      approvedAt\n    }\n    userVideoApprovals {\n      ...UserVideoApprovalFragment\n    }\n    voiceOverProduct {\n      id\n      isDelivered\n    }\n    derivedUserVideos {\n      totalCount\n    }\n    originalUserVideo {\n      id\n      guid\n      title\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserVideoRendersFragment on UserVideoNode {\n    id\n    currentConfiguredVideo {\n      id\n      guid\n      renderStatus\n      thumbnailUrl\n      renderedVideos {\n        edges {\n          node {\n            id\n            guid\n            renderUrl\n            renderFormat\n            renderedAt\n            renderStatus\n            renderSize\n            hasWatermark\n            createdAt\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment UserVideoRendersFragment on UserVideoNode {\n    id\n    currentConfiguredVideo {\n      id\n      guid\n      renderStatus\n      thumbnailUrl\n      renderedVideos {\n        edges {\n          node {\n            id\n            guid\n            renderUrl\n            renderFormat\n            renderedAt\n            renderStatus\n            renderSize\n            hasWatermark\n            createdAt\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment UserVideoFeedbackFragment on UserVideoFeedbackNode {\n    id\n    guid\n    rating\n    feedbackText\n    timeToCreate\n  }\n',
): (typeof documents)['\n  fragment UserVideoFeedbackFragment on UserVideoFeedbackNode {\n    id\n    guid\n    rating\n    feedbackText\n    timeToCreate\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation CreateUserVideoFeedback($input: CreateUserVideoFeedbackMutationInput!) {\n    createUserVideoFeedback(input: $input) {\n      updatedUserVideo {\n        id\n        guid\n        feedback {\n          edges {\n            node {\n              ...UserVideoFeedbackFragment\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateUserVideoFeedback($input: CreateUserVideoFeedbackMutationInput!) {\n    createUserVideoFeedback(input: $input) {\n      updatedUserVideo {\n        id\n        guid\n        feedback {\n          edges {\n            node {\n              ...UserVideoFeedbackFragment\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountUserVideoCount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      userVideos {\n        totalCount\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountUserVideoCount($accountGUID: String) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      userVideos {\n        totalCount\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountPageUserVideos(\n    $accountGUID: String!\n    $first: Int\n    $after: String\n    $orderBy: [String]\n    $isPurchased: Boolean\n    $searchQuery: String\n  ) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      unfilteredUserVideos: userVideos {\n        totalCount\n      }\n      userVideos(\n        first: $first\n        after: $after\n        orderBy: $orderBy\n        isPurchased: $isPurchased\n        searchQuery: $searchQuery\n      ) {\n        totalCount\n        edges {\n          cursor\n          node {\n            ...UserVideoAccountPageFieldsFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountPageUserVideos(\n    $accountGUID: String!\n    $first: Int\n    $after: String\n    $orderBy: [String]\n    $isPurchased: Boolean\n    $searchQuery: String\n  ) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      unfilteredUserVideos: userVideos {\n        totalCount\n      }\n      userVideos(\n        first: $first\n        after: $after\n        orderBy: $orderBy\n        isPurchased: $isPurchased\n        searchQuery: $searchQuery\n      ) {\n        totalCount\n        edges {\n          cursor\n          node {\n            ...UserVideoAccountPageFieldsFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AccountPageUserVideoAndDerivedVideos(\n    $userVideoGUID: String\n    $orderBy: [String]\n    $searchQuery: String\n  ) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      ...UserVideoAccountPageFieldsFragment\n      derivedAccountPageUserVideos: derivedUserVideos(\n        orderBy: $orderBy\n        searchQuery: $searchQuery\n      ) {\n        totalCount\n        edges {\n          node {\n            ...UserVideoAccountPageFieldsFragment\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query AccountPageUserVideoAndDerivedVideos(\n    $userVideoGUID: String\n    $orderBy: [String]\n    $searchQuery: String\n  ) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      ...UserVideoAccountPageFieldsFragment\n      derivedAccountPageUserVideos: derivedUserVideos(\n        orderBy: $orderBy\n        searchQuery: $searchQuery\n      ) {\n        totalCount\n        edges {\n          node {\n            ...UserVideoAccountPageFieldsFragment\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment SDKUserVideo on UserVideoNode {\n    ...CoreUserVideoFragment\n    ...UserVideoRendersFragment\n  }\n',
): (typeof documents)['\n  fragment SDKUserVideo on UserVideoNode {\n    ...CoreUserVideoFragment\n    ...UserVideoRendersFragment\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query SDKAllAccountUserVideos($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      userVideos {\n        edges {\n          node {\n            ...SDKUserVideo\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SDKAllAccountUserVideos($accountGUID: String!) {\n    accountByGuid(guid: $accountGUID) {\n      id\n      userVideos {\n        edges {\n          node {\n            ...SDKUserVideo\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserVideoByGUID($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      ...SDKUserVideo\n    }\n  }\n',
): (typeof documents)['\n  query UserVideoByGUID($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      ...SDKUserVideo\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query UserVideoApprovals($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      userVideoApprovals {\n        ...UserVideoApprovalFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query UserVideoApprovals($userVideoGUID: String!) {\n    userVideoByGuid(guid: $userVideoGUID) {\n      id\n      guid\n      userVideoApprovals {\n        ...UserVideoApprovalFragment\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query VariantForAI(\n    $aspectRatio: String\n    $displayDuration: Int\n    $vibe: String\n    $hasFootage: Boolean\n    $lastSelectedVariantGuid: String\n  ) {\n    videoTemplateVariantForAi(\n      aspectRatio: $aspectRatio\n      displayDuration: $displayDuration\n      vibe: $vibe\n      hasFootage: $hasFootage\n      lastSelectedVariantGuid: $lastSelectedVariantGuid\n    ) {\n      id\n      guid\n      createdAt\n      slug\n      omniGroupSlug\n      displayName\n      displayDuration\n      previewVideoUrl\n      thumbnailImageUrl\n      isPartnerExclusive\n      width\n      height\n      price\n      vibe\n      compoundSlug\n      aspectRatio\n    }\n  }\n',
): (typeof documents)['\n  query VariantForAI(\n    $aspectRatio: String\n    $displayDuration: Int\n    $vibe: String\n    $hasFootage: Boolean\n    $lastSelectedVariantGuid: String\n  ) {\n    videoTemplateVariantForAi(\n      aspectRatio: $aspectRatio\n      displayDuration: $displayDuration\n      vibe: $vibe\n      hasFootage: $hasFootage\n      lastSelectedVariantGuid: $lastSelectedVariantGuid\n    ) {\n      id\n      guid\n      createdAt\n      slug\n      omniGroupSlug\n      displayName\n      displayDuration\n      previewVideoUrl\n      thumbnailImageUrl\n      isPartnerExclusive\n      width\n      height\n      price\n      vibe\n      compoundSlug\n      aspectRatio\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query AudioForAI($duration: Int, $vibe: String) {\n    videoTemplateAudioForAi(duration: $duration, vibe: $vibe) {\n      id\n      name\n      transcodedAudio\n      duration\n    }\n  }\n',
): (typeof documents)['\n  query AudioForAI($duration: Int, $vibe: String) {\n    videoTemplateAudioForAi(duration: $duration, vibe: $vibe) {\n      id\n      name\n      transcodedAudio\n      duration\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CoreVideoLibraryVideoFields on VideoAssetLibraryVideoNode {\n    id\n    pk\n    guid\n    uploadKey\n    displayName\n    width\n    height\n    length\n    order\n    updatedAt\n    removedAt\n  }\n',
): (typeof documents)['\n  fragment CoreVideoLibraryVideoFields on VideoAssetLibraryVideoNode {\n    id\n    pk\n    guid\n    uploadKey\n    displayName\n    width\n    height\n    length\n    order\n    updatedAt\n    removedAt\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment CoreVideoLibraryFields on VideoAssetLibraryNode {\n    id\n    pk\n    slug\n    displayName\n\n    videoAssets {\n      edges {\n        node {\n          ...CoreVideoLibraryVideoFields\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment CoreVideoLibraryFields on VideoAssetLibraryNode {\n    id\n    pk\n    slug\n    displayName\n\n    videoAssets {\n      edges {\n        node {\n          ...CoreVideoLibraryVideoFields\n        }\n      }\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
