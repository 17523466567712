export const sizes = {
  none: 0,
  auto: 'auto',
  inherit: 'inherit',
  '1px': '0.0625rem',
  '2px': '0.125rem',
  '4px': '0.25rem',
  '6px': '0.375rem',
  '8px': '0.5rem',
  '12px': '0.75rem',
  '16px': '1rem',
  '20px': '1.25rem',
  '24px': '1.5rem',
  '32px': '2rem',
  '48px': '3rem',
  '64px': '4rem',
  '120px': '7.5rem',
  '50%': '50%',
  '100%': '100%',
  '100vh': '100vh',
  '100vw': '100vw',
} as const;
